<template>
  <div>
    <text-input-vertical
      :id="id"
      :input-name="name"
      :label="label"
      :rules="validationRules"
      :placeholder="placeholder"
      :value="value"
      @input="onInput">
      <template #errors="{ errors }">
        <div class="col-xs-6 error-text top-5 no-padding">
          {{ errors }}
        </div>
      </template>
    </text-input-vertical>

    <div class="text-right dark-gray-text top-5">
      <span :class="{ 'error-text': charLimitPassed }"><i class="size-text-12px">{{ charCount }} / {{ maxChars }} characters</i></span>
    </div>
  </div>
</template>

<script>
import TextInputVertical from 'vue-app/shared/components/text-input-vertical.vue';

export default {
  name: 'TextInputVerticalWithCharCount',

  components: {
    TextInputVertical
  },

  props: {
    id: {
      type: String,
      required: true
    },

    value: {
      type: String,
      default: ''
    },

    name: {
      type: String,
      required: true
    },

    label: {
      type: String,
      required: false
    },

    maxChars: {
      type: Number,
      default: 500
    },

    rules: {
      type: String,
      default: ''
    },

    placeholder: {
      type: String,
      required: false
    }
  },

  computed: {
    charLimitPassed() {
      return this.charCount > this.maxChars;
    },

    charCount() {
      return this.value?.length || 0;
    },

    isValid() {
      return !this.charLimitPassed;
    },

    validationRules() {
      return `${this.rules}|max:${this.maxChars}`;
    }
  },

  methods: {
    onInput(newValue) {
      this.$emit('input', newValue);
    }
  }
};
</script>

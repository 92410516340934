import { times } from  'lodash';

const colors = {
  default: [
    '#9213b2', '#f74d71', '#072a80', '#339699', '#f48ab9',
    '#7cb0fa', '#5459d2', '#eaca62', '#68cfd5', '#d8824a'
  ],

  purpleShades: [
    '#460059', '#610779', '#780096', '#9213B2', '#B148CA',
    '#CA7CDD', '#E6A5F4'
  ]
};

const generateColor = function (index, palette = 'default') {
  return colors[palette][index % 10];
};

export default {
  generate: function (count, palette = 'default') {
    return times(count, (index) => generateColor(index, palette));
  },

  red: function () {
    return '#f74d71';
  },

  blue: function () {
    return '#072a80';
  },

  purple: function () {
    return '#9213b2';
  },

  teal: function () {
    return '#246b6d';
  },

  scoutBlue: function () {
    return '#2331BD';
  },

  scoutRed: function () {
    return '#D13737';
  },

  scoutGray: function () {
    return '#B8B8B8';
  }
};

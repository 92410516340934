<template>
  <div>
    <text-input-vertical
      :id="id"
      :input-name="name"
      :label="label"
      :rules="validationRules"
      :placeholder="placeholder"
      :value="value"
      @input="onInput">
      <template #errors="{ errors }">
        <div class="col-xs-6 error-text top-5 no-padding">
          {{ errors }}
        </div>
      </template>
    </text-input-vertical>

    <div class="text-right dark-gray-text top-5">
      <span :class="{ 'error-text': wordLimitPassed }"><i class="size-text-12px">{{ wordCount }} / {{ maxWords }} Words</i></span>
    </div>
  </div>
</template>

<script>
import TextInputVertical from 'vue-app/shared/components/text-input-vertical.vue';
import { words } from 'lodash';

export default {
  name: 'TextInputVerticalWithWordCount',

  components: {
    TextInputVertical
  },

  props: {
    id: {
      type: String,
      required: true
    },

    value: {
      type: String,
      default: ''
    },

    name: {
      type: String,
      required: true
    },

    label: {
      type: String,
      required: false
    },

    maxWords: {
      type: Number,
      default: 20
    },

    rules: {
      type: String,
      default: ''
    },

    placeholder: {
      type: String,
      required: false
    }
  },

  computed: {
    wordLimitPassed() {
      return this.wordCount > this.maxWords;
    },

    wordCount() {
      return words(this.value).length;
    },

    isValid() {
      return !this.wordLimitPassed;
    },

    validationRules() {
      return `${this.rules}|maxWords:${this.maxWords}`;
    }
  },

  methods: {
    onInput(value) {
      this.$emit('input', value);
    }
  }
};
</script>

<template>
  <edit-modal :title="headerText" modal-size="lg" :on-save="save" :input="aggregatedLocations" v-model="lawFirmLocationsCopy">
    <template #modal-trigger="{ openModal }">
      <a href="" class="normal-weight size-text-12px" @click.prevent="openModal">Edit</a>
    </template>

    <template #default v-if="lawFirmLocationsCopy">
      <div class="bold-weight text-uppercase bottom-10">
        Office Locations
      </div>

      <hr class="kenny-hr no-margin">

      <div class="form-section-content top-30">
        <div v-for="(location, index) in visibleLocations" :key="locationId(location)">
          <div class="row tight-columns">
            <div class="top-10 bottom-20" v-if="index !== 0">
              <hr class="kenny-hr no-margin">
            </div>

            <div class="col-sm-4 bottom-10">
              <typeahead-vertical
                :ref="`countryTypeahead${locationId(location)}`"
                :id="`country${locationId(location)}`"
                :options="countryOptions"
                :hide-icon="true"
                :value="location.country"
                placeholder="Enter country"
                @input="selectCountry(location, $event)"
                rules="required">
              </typeahead-vertical>
            </div>

            <div class="col-sm-4 bottom-10">
              <typeahead-vertical
                :ref="`stateTypeahead${locationId(location)}`"
                :id="`state${locationId(location)}`"
                :options="stateOptions"
                :option-label-key="'name'"
                :option-value-key="'name'"
                :hide-icon="true"
                :value="location.state || ''"
                :placeholder="isInUS(location) ? 'Enter state' : 'N/A'"
                @input="selectState(location, $event)"
                :disabled="!isInUS(location)"
                :rules="isInUS(location) ? 'required' : ''">
              </typeahead-vertical>
            </div>

            <div class="col-sm-4 bottom-10">
              <text-input-vertical
                :id="`city-${locationId(location)}`"
                :input-name="`city${locationId(location)}`"
                :placeholder="'City'"
                v-model="location.city"
                rules="required">
              </text-input-vertical>

              <div v-if="visibleLocations.length > 1" @click="onRemove(location)">
                <svg-icon name="x3" class="deselect-icon for-tight-column-input"></svg-icon>
              </div>
            </div>
          </div>
        </div>

        <div class="row tight-columns top-20">
          <div class="col-sm-8 col-md-4 col-md-offset-8">
            <button
              type="button"
              class="empty-button a-button-size"
              @click="onAdd"
              :disabled="!formIsValid">
              {{ addLocationButtonText }}
            </button>
          </div>
        </div>
      </div>
    </template>
  </edit-modal>
</template>

<script>
import EditModal from 'vue-app/shared/components/edit-modal.vue';
import TextInputVertical from 'vue-app/shared/components/text-input-vertical.vue';
import TypeaheadVertical from 'vue-app/shared/components/typeahead-vertical.vue';
import SvgIcon from 'vue-app/shared/components/svg-icon.vue';
import SharedListsService from 'vue-app/shared/services/shared-lists-service.js';
import { uniqueId } from 'lodash';

export default {
  name: 'FirmLocationsEditModal',

  components: {
    EditModal,
    TextInputVertical,
    TypeaheadVertical,
    SvgIcon
  },

  props: {
    lawFirm: {
      type: Object,
      required: true
    },

    onSave: {
      type: Function,
      required: true
    }
  },

  data() {
    return {
      lawFirmLocationsCopy: null
    };
  },

  computed: {
    headerText() {
      return `Editing Your ${this.lawFirm.alspAccount ? 'Company\'s Information' : 'Firm\'s Profile'}`;
    },

    aggregatedLocations() {
      return this.lawFirm.lawFirmLocationsAttributes.map(location => ({ ...location, _destroy: false }));
    },

    visibleLocations() {
      return this.lawFirmLocationsCopy.filter(location => !location._destroy);
    },

    stateOptions() {
      return SharedListsService.listOfStateObjects();
    },

    countryOptions() {
      return SharedListsService.listOfCountries();
    },

    addLocationButtonText() {
      const determiner = this.visibleLocations.length >= 1 ? 'Another' : 'A';

      return `Add ${determiner} Location`;
    },

    formIsValid() {
      return !this.visibleLocations.some(location => !location.country || !location.city);
    }
  },

  methods: {
    selectCountry(location, country) {
      location.country = country;

      if (!this.isInUS(location)) {
        this.$refs[`stateTypeahead${this.locationId(location)}`][0].reset();
      }
    },

    selectState(location, state) {
      location.state = state;
    },

    locationId(location) {
      return location.id || location.auxiliaryId;
    },

    isInUS(location) {
      return location.country === 'United States';
    },

    onRemove(location) {
      location._destroy = true;
    },

    onAdd() {
      this.lawFirmLocationsCopy.push({
        auxiliaryId: uniqueId('new'),
        country: 'United States',
        state: '',
        city: '',
        _destroy: false
      });
    },

    save() {
      const editedLawFirm = { ...this.lawFirm, lawFirmLocationsAttributes: this.lawFirmLocationsCopy };

      this.onSave(editedLawFirm);
    }
  }
};
</script>

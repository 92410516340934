<template>
  <edit-modal :title="headerText" :input="lawFirm.description" v-model="descriptionCopy" modal-size="lg" :on-save="save">
    <template #modal-trigger="{ openModal }">
      <a href="" class="normal-weight size-text-12px" @click.prevent="openModal">Edit</a>
    </template>

    <template #default>
      <div class="bold-weight text-uppercase bottom-10">
        About {{ lawFirm.alspAccount ? 'Company' : 'Firm' }}
      </div>

      <hr class="kenny-hr no-margin">

      <div class="form-section-content top-30">
        <textarea-input-vertical
          id="firm-description"
          :rules="`required|maxWords:${wordLimit}`"
          :rows="6"
          :placeholder="`Describe Your Firm/Company (Limit: ${wordLimit} Words)`"
          v-model="descriptionCopy">
          <template #errors="{ errors }">
            <div class="col-xs-6 error-text top-5 no-padding">
              {{ errors }}
            </div>
          </template>
        </textarea-input-vertical>

        <div class="text-right dark-gray-text top-5">
          <span :class="{ 'error-text': wordLimitPassed(descriptionCopy) }">
            <i class="size-text-12px">{{ wordCount(descriptionCopy) }} / {{ wordLimit }} Words</i>
          </span>
        </div>
      </div>
    </template>
  </edit-modal>
</template>

<script>
import { words } from 'lodash';
import EditModal from 'vue-app/shared/components/edit-modal.vue';
import TextareaInputVertical from 'vue-app/shared/components/textarea-input-vertical.vue';

export default {
  name: 'AboutForm',

  components: {
    EditModal,
    TextareaInputVertical
  },

  props: {
    lawFirm: {
      type: Object,
      required: true
    },

    onSave: {
      type: Function,
      required: true
    }
  },

  data() {
    return {
      wordLimit: 500,
      descriptionCopy: null
    };
  },

  computed: {
    headerText() {
      return `Editing Your ${this.lawFirm.alspAccount ? 'Company\'s Information' : 'Firm\'s Profile'}`;
    }
  },

  methods: {
    save() {
      this.onSave({ ...this.lawFirm, description: this.descriptionCopy });
    },

    wordCount(text) {
      return words(text).length;
    },

    wordLimitPassed(text) {
      return this.wordCount(text) > this.wordLimit;
    }
  }
};
</script>

<template>
  <div id="alsp-testimonials" class="shadowed-box">
    <div class="box-header">
      <div class="row tight-columns">
        <div :class="['col-xs-12', { 'col-sm-9': isLawyer }]">
          <span class="text-uppercase">Testimonials</span>
          <span class="left-10 dark-gray-text normal-weight size-text-12px" v-if="hasTestimonials">({{ testimonials.length }} Result{{ testimonials.length > 1 ? 's' : '' }})</span>
        </div>

        <div class="col-sm-3 text-right-not-xs" v-if="isLawyer">
          <multifield-line-items-edit-modal
            :is-alsp="lawFirm.alspAccount"
            :law-firm-items="lawFirm.alspTestimonialsAttributes"
            single-item-name="testimonial"
            item-class-name="Testimonial"
            item-title-key="reviewerName"
            item-title-detail-key="reviewerCompany"
            item-description-key="body"
            :on-save="save">
            <template #modalTrigger="{ openModal }">
              <a href="" class="normal-weight size-text-12px" @click.prevent="openModal">Manage Testimonials</a>
            </template>

            <template #inputFields="{ item: testimonial, setFormValidity }">
              <alsp-testimonial-fields
                :testimonial="testimonial"
                :on-form-validity-change="setFormValidity">
              </alsp-testimonial-fields>
            </template>
          </multifield-line-items-edit-modal>
        </div>
      </div>
    </div>

    <div class="box-content">
      <div class="gray-text size-text-16px top-10 bottom-10" v-if="!hasTestimonials">
        <div v-if="isLawyer">
          Your company does not yet have any testimonials.
        </div>

        <div v-if="!isLawyer">
          None Provided
        </div>
      </div>

      <div v-for="(testimonial, index) in testimonials" :key="testimonial.id">
        <div class="size-text-base semibold-weight bottom-15">
          &ldquo;{{ testimonial.body }}&rdquo;
        </div>

        <div class="dark-gray-text">
          <span class="semibold-weight">{{ testimonial.reviewerName }}</span><span v-if="testimonial.reviewerCompany">, {{ testimonial.reviewerCompany }}</span>
        </div>

        <hr class="kenny-hr" v-if="!isLast(index)">
      </div>
    </div>
  </div>
</template>

<script>
import MultifieldLineItemsEditModal from 'vue-app/marketplace/lawyer/firms/multifield-line-items-edit-modal.vue';
import AlspTestimonialFields from 'vue-app/marketplace/lawyer/firms/alsp-testimonial-fields.vue';

export default {
  name: 'AlspTestimonials',

  components: {
    MultifieldLineItemsEditModal,
    AlspTestimonialFields
  },

  props: {
    lawFirm: {
      type: Object,
      required: true
    },

    isLawyer: {
      type: Boolean,
      default: false
    },

    onSave: {
      type: Function,
      required: false
    }
  },

  computed: {
    testimonials: function () {
      return this.lawFirm.alspTestimonialsAttributes;
    },

    hasTestimonials: function () {
      return this.testimonials.length > 0;
    }
  },

  methods: {
    isLast: function (index) {
      return index === this.testimonials.length - 1;
    },

    save(alspTestimonialsAttributes) {
      return this.onSave({ ...this.lawFirm, alspTestimonialsAttributes }, 'testimonials');
    }
  }
};
</script>

<template>
  <div id="alsp-members" class="shadowed-box">
    <div class="box-header">
      <div class="row tight-columns">
        <div :class="['col-xs-12 text-uppercase', { 'col-sm-9': isLawyer }]">
          Key Professionals
        </div>

        <div class="col-xs-12 col-sm-3 text-right-not-xs" v-if="isLawyer">
          <multifield-line-items-edit-modal
            :is-alsp="lawFirm.alspAccount"
            :law-firm-items="lawFirm.alspMembersAttributes"
            single-item-name="professional"
            item-class-name="KeyProfessional"
            item-title-key="fullName"
            item-title-detail-key="title"
            item-subtitle-key="location"
            item-description-key="bio"
            :item-initialize-data="itemInitializeData"
            :item-limit="3"
            :on-save="save">
            <template #inputFields="{item: member, setFormValidity}">
              <alsp-member-fields
                :member="member"
                :on-form-validity-change="setFormValidity">
              </alsp-member-fields>
            </template>
          </multifield-line-items-edit-modal>
        </div>
      </div>
    </div>

    <div class="box-content">
      <div class="gray-text size-text-16px top-10 bottom-10" v-if="!hasFeaturedMembers">
        None Provided
      </div>

      <div v-for="(member, index) in members" :key="member.id">
        <div class="inline-flex">
          <div class="right-20">
            <profile-photo
              :user="member"
              :editable="isLawyer"
              @click="editMemberProfilePhoto(member)">
            </profile-photo>
          </div>

          <div>
            <div class="bottom-15">
              <div class="size-text-17px">
                {{ member.firstName }} {{ member.lastName }}
              </div>

              <div class="top-2" v-if="member.title || member.city">
                {{ member.title }}
                <span class="left-5 right-5" v-if="member.title && member.city">&#8231;</span>
                {{ member.city }}
              </div>
            </div>

            <div>
              <span class="simple-text">{{ member.bio }}</span>
            </div>
          </div>
        </div>

        <hr class="kenny-hr" v-if="!isLastMember(index)">
      </div>
    </div>

    <edit-alsp-member-photo-modal
      ref="editAlspMemberPhotoModal"
      :user="memberToEdit"
      @photo-updated="onPhotoUpdated">
    </edit-alsp-member-photo-modal>
  </div>
</template>

<script>
import ProfilePhoto from 'vue-app/shared/components/profile-photo.vue';
import EditAlspMemberPhotoModal from 'vue-app/marketplace/lawyer/firms/edit-alsp-member-photo-modal.vue';
import MultifieldLineItemsEditModal from 'vue-app/marketplace/lawyer/firms/multifield-line-items-edit-modal.vue';
import AlspMemberFields from 'vue-app/marketplace/lawyer/firms/alsp-member-fields.vue';

export default {
  name: 'AlspMembers',

  components: {
    ProfilePhoto,
    EditAlspMemberPhotoModal,
    MultifieldLineItemsEditModal,
    AlspMemberFields
  },

  props: {
    lawFirm: {
      type: Object,
      required: true
    },

    isLawyer: {
      type: Boolean,
      default: false
    },

    onSave: {
      type: Function,
      required: false
    }
  },

  data() {
    return {
      memberToEdit: {},

      itemInitializeData: {
        country: 'United States'
      }
    };
  },

  computed: {
    members() {
      return this.lawFirm.alspMembersAttributes.map(member => {
        return { ...member, fullName: `${member.firstName} ${member.lastName}` };
      });
    },

    hasFeaturedMembers() {
      return this.members?.length > 0;
    }
  },

  methods: {
    isLastMember(index) {
      return index === this.members.length - 1;
    },

    editMemberProfilePhoto(member) {
      this.memberToEdit = member;
      this.$refs.editAlspMemberPhotoModal.openModal();
    },

    onPhotoUpdated(member) {
      const index = this.lawFirm.alspMembersAttributes.findIndex(m => m.id === member.id);

      if (index !== -1) {
        this.lawFirm.alspMembersAttributes.splice(index, 1, member);
      }
    },

    save(alspMembersAttributes) {
      return this.onSave({ ...this.lawFirm, alspMembersAttributes }, 'key professionals');
    }
  }
};
</script>

<style lang="scss" scoped>
  .inline-flex {
    display: inline-flex;
  }
</style>

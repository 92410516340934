<template>
  <div>
    <ul :class="columnClass">
      <li v-for="(item, index) in renderingLegends" :key="index">
        <chart-legend :item="item" :italicized="item.italicized"></chart-legend>
      </li>
    </ul>

    <div class="text-left top-15" v-if="legendData.length > initialMaxLegends">
      <a href="" class="semibold-weight" @click.prevent="toggleShowMore">{{ viewMoreOrLess }}</a>
    </div>
  </div>
</template>

<script>
import ChartLegend from './chart-legend.vue';
import { includes } from 'lodash';

export default {
  name: 'ChartLegends',

  components: {
    ChartLegend
  },

  props: {
    legendData: {
      type:     Array,
      required: true
    },

    columnCount: {
      type: Number,
      default: 1,
      validator: (value) => { return includes([1, 2], value); }
    },

    initialMaxLegends: {
      type: Number,
      default: 4
    }
  },

  data() {
    return {
      showMore: false
    };
  },

  computed: {
    columnClass() {
      return `column-count-${this.columnCount}`;
    },

    limitedLegends() {
      return this.legendData.slice(0, this.initialMaxLegends);
    },

    renderingLegends() {
      return this.showMore ? this.legendData : this.limitedLegends;
    },

    viewMoreOrLess() {
      return this.showMore ? 'View Less' : 'View More';
    }
  },

  methods: {
    toggleShowMore() {
      this.showMore = !this.showMore;
    }
  }
};
</script>

<style lang="scss" scoped>
  ul {
    margin-top: 20px;
    margin-bottom: 0;
    padding: 0;
    text-align: left;
    list-style-type: none;

    li {
      line-height: 16px;
      margin-bottom: 10px;
      width: 100%;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  @media (min-width: 768px) {
    ul {
      display: flex;
      flex-wrap: wrap;
      margin-top: 0;

      &.column-count-1 { column-count: 1; }
      &.column-count-2 { column-count: 2; }
    }
  }
</style>

<template>
  <div class="form-section-content">
    <div class="bottom-20">
      <text-input-vertical-with-char-count
        label="Reviewer Name"
        :id="`reviewer-name-${testimonialId}`"
        :name="`reviewerName${testimonialId}`"
        :max-chars="100"
        placeholder="Reviewer Name"
        v-model="testimonial.reviewerName"
        rules="required">
      </text-input-vertical-with-char-count>
    </div>

    <div class="bottom-20">
      <text-input-vertical-with-char-count
        label="Reviewer Company"
        :id="`reviewer-company-${testimonialId}`"
        :name="`reviewerCompany${testimonialId}`"
        :max-chars="100"
        placeholder="Reviewer Company"
        v-model="testimonial.reviewerCompany"
        rules="required">
      </text-input-vertical-with-char-count>
    </div>

    <div>
      <textarea-input-vertical-with-char-count
        label="Review"
        :id="`review-body-${testimonialId}`"
        :name="`reviewBody${testimonialId}`"
        :rows="4"
        :max-chars="500"
        placeholder="Review Body"
        v-model="testimonial.body"
        rules="required">
      </textarea-input-vertical-with-char-count>
    </div>
  </div>
</template>

<script>
import TextInputVerticalWithCharCount from 'vue-app/shared/components/text-input-vertical-with-char-count.vue';
import TextareaInputVerticalWithCharCount from 'vue-app/shared/components/textarea-input-vertical-with-char-count.vue';
import { trim } from 'lodash';

export default {
  name: 'AlspTestimonialFields',

  components: {
    TextareaInputVerticalWithCharCount,
    TextInputVerticalWithCharCount
  },

  props: {
    testimonial: {
      type: Object,
      required: true
    },

    onFormValidityChange: {
      type: Function,
      required: true
    }
  },

  computed: {
    testimonialId() {
      return this.testimonial.id || 'new';
    },

    isValid() {
      const { reviewerName, reviewerCompany, body } = this.testimonial;

      const isBodyValid = body && trim(body).length <= 500;
      const isReviewerNameValid = reviewerName && reviewerName.length <= 100;
      const isReviewerCompanyValid = reviewerCompany && reviewerCompany.length <= 100;

      return isBodyValid && isReviewerNameValid && isReviewerCompanyValid;
    }
  },

  watch: {
    isValid(newValue, _oldValue) {
      this.onFormValidityChange(newValue);
    }
  },

  mounted() {
    this.onFormValidityChange(this.isValid);
  }
};
</script>

<template>
  <div id="firm-highlights" class="shadowed-box">
    <div class="box-header text-uppercase">
      Highlights
    </div>

    <div class="box-content">
      <div>
        <div class="row tight-columns bottom-5">
          <div :class="['col-xs-12 semibold-weight', { 'col-sm-9': isLawyer }]">
            Representative Clients
          </div>

          <div class="col-xs-12 col-sm-3 size-text-12px text-right-not-xs" v-if="isLawyer">
            <multifield-line-items-edit-modal
              :is-alsp="lawFirm.alspAccount"
              :law-firm-items="lawFirm.lawFirmRepresentativeClientsAttributes"
              item-class-name="RepresentativeClient"
              single-item-name="client"
              item-title-key="name"
              item-description-key="description"
              :on-save="saveRepresentativeClients">
              <template #inputFields="{ item: client, setFormValidity }">
                <representative-client-fields
                  :client="client"
                  :on-form-validity-change="setFormValidity">
                </representative-client-fields>
              </template>
            </multifield-line-items-edit-modal>
          </div>
        </div>

        <hr class="kenny-hr no-margin">

        <div class="tag-list top-10" v-if="hasRepresentativeClients">
          <div class="tag-list-item gray compact" v-for="client in lawFirm.lawFirmRepresentativeClientsAttributes" :key="client.id">
            {{ client.name }}
          </div>
        </div>

        <div class="missing-text top-10" v-else>
          None Provided
        </div>
      </div>

      <div class="top-30">
        <div class="row tight-columns bottom-5">
          <div :class="['col-xs-12 semibold-weight', { 'col-sm-9': isLawyer }]">
            Industries
          </div>

          <div class="col-xs-12 col-sm-3 size-text-12px text-right-not-xs" v-if="isLawyer">
            <typeahead-item-selector-edit-modal
              :is-alsp="lawFirm.alspAccount"
              :law-firm-items="lawFirm.lawFirmIndustriesAttributes"
              :selectable-items="industries"
              selectable-item-id-attribute="industryId"
              item-class-name="Industry"
              :on-save="saveIndustries">
            </typeahead-item-selector-edit-modal>
          </div>
        </div>

        <hr class="kenny-hr no-margin">

        <div class="tag-list top-10" v-if="hasIndustries">
          <div class="tag-list-item gray compact" v-for="industry in lawFirm.lawFirmIndustriesAttributes" :key="industry.id">
            {{ industry.label }}
          </div>
        </div>

        <div class="missing-text top-10" v-else>
          None Provided
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Industry from 'resources/industry.js';
import TypeaheadItemSelectorEditModal from 'vue-app/marketplace/lawyer/firms/typeahead-item-selector-edit-modal.vue';
import MultifieldLineItemsEditModal from 'vue-app/marketplace/lawyer/firms/multifield-line-items-edit-modal.vue';
import RepresentativeClientFields from 'vue-app/marketplace/lawyer/firms/representative-client-fields.vue';

export default {
  name: 'FirmHighlights',

  components: {
    TypeaheadItemSelectorEditModal,
    MultifieldLineItemsEditModal,
    RepresentativeClientFields
  },

  props: {
    lawFirm: {
      type: Object,
      required: true
    },

    isLawyer: {
      type: Boolean,
      default: false
    },

    onSave: {
      type: Function,
      required: false
    }
  },

  data() {
    return {
      industries: []
    };
  },

  computed: {
    hasRepresentativeClients() {
      return this.lawFirm.lawFirmRepresentativeClientsAttributes?.length;
    },

    hasIndustries() {
      return this.lawFirm.lawFirmIndustriesAttributes?.length;
    }
  },

  mounted() {
    Industry.query().then((industries) => { this.industries = industries; });
  },

  methods: {
    saveIndustries(lawFirmIndustriesAttributes) {
      return this.onSave({ ...this.lawFirm, lawFirmIndustriesAttributes }, 'industries');
    },

    saveRepresentativeClients(lawFirmRepresentativeClientsAttributes) {
      return this.onSave({ ...this.lawFirm, lawFirmRepresentativeClientsAttributes }, 'representative clients');
    }
  }
};
</script>

<template>
  <section class="uiv">
    <modal id="edit-alsp-member-profile-photo" v-model="modalOpen" size="md" :header="false" :footer="false" :backdrop="false" append-to-body @show="onModalShow">
      <div class="close-modal full-screen" data-toggle="modal" data-target="edit-alsp-member-profile-photo" @click="cancel">
        <svg-icon name="x3" class="base-icon"></svg-icon>
      </div>

      <div class="notice-modal">
        <div class="notice-header">
          <slot name="title">
            Editing Photo
          </slot>
        </div>

        <div class="notice-regular-text">
          <div v-show="validPhotoSelected" class="bottom-30 cropper-container">
            <loading-section name="memberPhotoCropper">
              <vue-cropper
                ref="cropper"
                :container-style="{ height: '360px' }"
                :img-style="{ height: '360px', width: 'unset', margin: 'auto' }"
                :aspect-ratio="1"
                :zoomable="false"
                :view-mode="2"
                :background="false"
                :min-container-height="360"
                :min-canvas-height="360"
                :toggle-drag-mode-on-dblclick="false"
                @ready="onCropperReady"
                :src="imageUrl">
              </vue-cropper>
            </loading-section>
          </div>

          <div v-show="!photoSelected || hasErrors" :class="['gray-text', 'size-text-base', 'text-center', 'top-30', { 'bottom-60': !hasErrors, 'bottom-30': hasErrors }]">
            You have not selected a photo yet.
          </div>

          <div v-if="hasErrors" class="bottom-30">
            <div class="red-text size-text-13px semibold-weight text-center" v-for="error in errors" :key="error">
              {{ error }}
            </div>
          </div>

          <div class="row bottom-5">
            <div :class="['col-sm-6', { 'col-sm-offset-3': !validPhotoSelected }]">
              <label :for="`file-input-${user.id}`" class="nv-button-white smaller">
                {{ validPhotoSelected ? 'Select New Image' : 'Select Image' }}
              </label>

              <input :id="`file-input-${user.id}`" type="file" accept=".jpg,.jpeg,.gif,.png,.bmp,.pdf" class="hidden" @change="onFileSelected">
            </div>

            <div v-if="validPhotoSelected" class="col-sm-6">
              <loading-button name="updateMemberPhoto" lb-class="nv-button-blue smaller" @lb-click="save">Save & Close</loading-button>
            </div>
          </div>
        </div>
      </div>
    </modal>
  </section>
</template>

<script>
import LoadingService from 'vue-app/shared/services/loading-service';
import LoadingButton from 'vue-app/shared/components/loading-button.vue';
import AlspMemberProfilePhoto from 'src/resources/alsp-member-profile-photo.js';
import VueCropper from 'vue-cropperjs';
import SvgIcon from 'vue-app/shared/components/svg-icon.vue';
import LoadingSection from 'vue-app/shared/components/loading-section.vue';
import modalToggle from 'vue-app/shared/mixins/modal-toggle';

export default {
  name: 'EditAlspMemberPhotoModal',

  components: {
    LoadingButton,
    VueCropper,
    SvgIcon,
    LoadingSection
  },

  mixins: [
    modalToggle
  ],

  props: {
    user: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      imageUrl: this.user.photo?.url,
      selectedFile: null,
      errors: []
    };
  },

  computed: {
    photoSelected() {
      return (this.selectedFile !== null || this.user.photo?.url);
    },

    hasErrors() {
      return this.errors.length > 0;
    },

    validPhotoSelected() {
      return this.photoSelected && !this.hasErrors;
    },

    cropper() {
      return this.$refs.cropper.cropper;
    }
  },

  watch: {
    modalOpen(newValue) {
      if (newValue) {
        LoadingService.loading('memberPhotoCropper');
      }
    }
  },

  methods: {
    onModalShow() {
      if (this.user.photo?.url) {
        this.fetchAndReplaceCropperImage(this.user.photo?.url);
      }
      else {
        LoadingService.done('memberPhotoCropper');
      }
    },

    onCropperReady() {
      const canvasData  = this.cropper.getCanvasData();

      const minCropBoxWidth  = 180 * (canvasData.width / canvasData.naturalWidth);
      const minCropBoxHeight = 180 * (canvasData.height / canvasData.naturalHeight);

      this.cropper.cropBoxData = {
        ...this.cropper.cropBoxData,
        minWidth: minCropBoxWidth,
        minHeight: minCropBoxHeight
      };

      this.cropper.setCropBoxData({
        width: minCropBoxWidth,
        height: minCropBoxHeight
      });
    },

    fetchAndReplaceCropperImage(url) {
      if (url === this.imageUrl) {
        LoadingService.done('memberPhotoCropper');
        return;
      }

      const vueInstance = this;

      return fetch(url)
      .then(response => response.blob())
      .then(blob => {
        vueInstance.replaceCropperImage(blob);
        LoadingService.done('memberPhotoCropper');
      });
    },

    onFileSelected(event) {
      this.selectedFile = event.target.files[0];

      if (!this.selectedFile.type.startsWith('image/')) {
        this.errors.push('Please select an image.');
        return;
      }

      this.replaceCropperImage(this.selectedFile);
    },

    validateImageSize(image) {
      if (image.width >= 180 && image.height >= 180) {
        this.errors = [];

        return;
      }

      if (image.width < 180) {
        this.errors.push('Image must be at least 180px wide. Your image is ' + image.width + 'px wide.');
      }

      if (image.height < 180) {
        this.errors.push('Image must be at least 180px tall. Your image is ' + image.height + 'px tall.');
      }
    },

    replaceCropperImage(file) {
      const vueInstance = this;
      const reader      = new FileReader();

      reader.readAsDataURL(file);

      reader.onloadend = (event) => {
        const image = new Image();
        image.onload = function () {
          vueInstance.validateImageSize(image);
        };

        image.src = event.target.result;
        vueInstance.$refs.cropper.replace(event.target.result);
      };
    },

    save() {
      this.data = this.$refs.cropper.getCropBoxData();

      this.$refs.cropper.getCroppedCanvas().toBlob((blob) => {
        const img = new Image();
        img.src = URL.createObjectURL(blob);

        img.onload = () => {
          const cropBoxData = this.$refs.cropper.getCropBoxData();
          const canvasData  = this.$refs.cropper.getCanvasData();
          const image       = this.$refs.cropper.getImageData();
          const ratio       = image.naturalWidth / canvasData.width;

          const x = ((cropBoxData.left - canvasData.left) * ratio);
          const y = cropBoxData.top * ratio;

          const params = {
            id: this.user.id,
            file: this.selectedFile,
            cropX: x,
            cropY: y,
            cropH: img.naturalHeight,
            cropW: img.naturalWidth
          };

          const vueInstance = this;

          LoadingService.loading('updateMemberPhoto');
          AlspMemberProfilePhoto.postForm(params)
            .then((response) => {
              vueInstance.$emit('photo-updated', response);

              this.selectedFile = null;
              this.errors       = [];

              this.dismissModal();
            })
            .catch(() => {
              vueInstance.errors.push('Error uploading image, please try again later.');
            })
            .finally(() => {
              LoadingService.done('updateMemberPhoto');
            });
        };
      });
    },

    cancel() {
      this.dismissModal();

      this.selectedFile = null;
      this.errors       = [];
      this.imageUrl     = this.user.photo?.url;

      this.fetchAndReplaceCropperImage(this.user.photo?.url);
    }
  }
};
</script>

<style lang="scss" scoped>
  label.nv-button-white {
    align-content: center;
  }

  :deep(.cropper-modal) {
    background-color: transparent;
    display: none;
  }

  :deep(.cropper-container) {
    overflow: hidden;
  }
</style>

<template>
  <div id="firm-testimonials" class="shadowed-box">
    <div class="box-header">
      <div class="row tight-columns">
        <div :class="['col-xs-12', { 'col-sm-9': isLawyer }]">
          <span class="text-uppercase">Testimonials</span>
          <span class="left-10 dark-gray-text normal-weight size-text-12px" v-if="reviewsWithQuotes.length > 0">({{ reviewsWithQuotes.length }} {{ $pluralize("Results", reviewsWithQuotes.length) }})</span>
        </div>

        <div class="col-xs-12 col-sm-3 text-right-not-xs" v-if="isLawyer">
          <a href="/lawyer-app/review-invites" class="normal-weight size-text-12px" target="_blank">Manage Invited Reviews</a>
        </div>
      </div>
    </div>

    <div class="box-content">
      <div class="gray-text size-text-base top-10 bottom-10" v-if="!reviewsWithQuotes.length">
        <div v-if="isLawyer">
          None of the lawyers in your firm have been reviewed yet. To invite a client to review your legal services outside of Priori, please <a href="/lawyer-app/review-invites" class="dark-gray-text semibold-weight" target="_blank">click here</a>.
        </div>

        <div v-else>
          None of the lawyers in this firm have been reviewed yet.
        </div>
      </div>

      <div v-for="(review, index) in reviewsWithQuotes" :key="review.id">
        <hr class="kenny-hr" v-if="index !== 0">

        <div class="size-text-base semibold-weight">
          &ldquo;{{ review.quote() }}&rdquo;
        </div>

        <div class="dark-gray-text top-15" v-if="review.client">
          <span class="semibold-weight">{{ review.client.fullName }}</span><span v-if="review.client.businessName">, {{ review.client.businessName }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FirmTestimonials',

  props: {
    reviewsWithQuotes: {
      type:    Array,
      default: () => []
    },

    isLawyer: {
      type:    Boolean,
      default: false
    }
  }
};
</script>

<template>
  <div id="firm-locations" class="shadowed-box">
    <div class="box-header">
      <div class="row tight-columns">
        <div :class="['col-xs-12 text-uppercase', { 'col-sm-9': isLawyer }]">
          Locations
        </div>

        <div class="col-xs-12 col-sm-3 text-right-not-xs" v-if="isLawyer">
          <firm-locations-edit-modal :law-firm="lawFirm" :on-save="save"></firm-locations-edit-modal>
        </div>
      </div>
    </div>

    <div class="box-content">
      <div class="gray-text size-text-16px" v-if="!hasLocations">
        None Provided
      </div>

      <div v-for="(locations, continent, index) in groupedLocations" :key="continent">
        <div class="row tight-columns location-header">
          <div class="col-xs-2">
            <svg-icon name="location" class="base-icon"></svg-icon>
          </div>

          <div class="col-xs-8">
            <div class="semibold-weight text-ellipsis">
              {{ continent }}
            </div>
          </div>

          <div class="col-xs-2 text-right">
            <a href="" class="nv-button-square-white" @click.prevent="toggleContinent(continent)"><svg-icon name="caret-right" :class="['base-icon smaller expand-icon', { 'expanded': isExpanded(continent) }]"></svg-icon></a>
          </div>
        </div>

        <div class="row tight-columns top-10" v-if="isExpanded(continent)">
          <div class="col-xs-2 col-sm-1 col-md-2">
          </div>

          <div class="col-xs-10 col-sm-11 col-md-10">
            <div :class="{ 'bottom-5': !isLastLocation(continent, innerIndex) }" v-for="(location, innerIndex) in locations" :key="location.city">
              {{ location.city }}
            </div>
          </div>
        </div>

        <hr class="kenny-hr tight" v-if="!isLastContinent(index)">
      </div>
    </div>
  </div>
</template>

<script>
import FirmLocationsEditModal from 'vue-app/marketplace/lawyer/firms/firm-locations-edit-modal.vue';
import SvgIcon from 'vue-app/shared/components/svg-icon.vue';
import { groupBy } from 'lodash';

export default {
  name: 'FirmLocations',

  components: {
    FirmLocationsEditModal,
    SvgIcon
  },

  props: {
    lawFirm: {
      type: Object,
      required: true
    },

    isLawyer: {
      type: Boolean,
      default: false
    },

    onSave: {
      type: Function,
      required: false
    }
  },

  data() {
    return {
      selectedContinents: {}
    };
  },

  computed: {
    groupedLocations() {
      return groupBy(this.lawFirm.lawFirmLocationsAttributes, 'continent');
    },

    hasLocations() {
      return this.lawFirm.lawFirmLocationsAttributes.length > 0;
    }
  },

  methods: {
    isLastContinent(index) {
      return index === Object.keys(this.groupedLocations).length - 1;
    },

    isLastLocation(continent, index) {
      return index === this.groupedLocations[continent].length - 1;
    },

    toggleContinent(continent) {
      this.$set(this.selectedContinents, continent, !this.selectedContinents[continent]);
    },

    isExpanded(continent) {
      return this.selectedContinents[continent];
    },

    save(editedLawFirm) {
      return this.onSave(editedLawFirm, 'firm locations');
    }
  }
};
</script>

<style lang="scss" scoped>
  .location-header {
    height: 22px;
  }

  .nv-button-square-white {
    display: inline-block;
    width: 22px;
    height: 22px;
    padding: 3px;

    :deep(.base-icon) {
      vertical-align: text-top;
    }
  }
</style>

<template>
  <priori-modal ref="modal" @on-modal-close="reset">
    <template #modal-trigger="{ openModal }">
      <slot name="modalTrigger" :open-modal="openModal">
        <a href="" class="bold-link blue-link size-text-12px" @click.prevent="openModal"><svg-icon name="invite" class="base-icon right-10"></svg-icon>Add New {{ lawFirm.alspAccount ? 'Admin' : 'Firm User' }}</a>
      </slot>
    </template>

    <template #title>
      Add User To <span :class="{ 'purple-text': lawFirm }">{{ lawFirm ? lawFirm.name : 'Firm' }}</span>
    </template>

    <template #default="{ closeModal }">
      <validation-observer v-slot="{ failed }" ref="validationObserver">
        <form role="form" name="form" class="a-form" novalidate @submit.prevent>
          <div class="bottom-30">
            <div class="bottom-30">
              <text-input-vertical
                v-model="lawyer.email"
                label="Email Address"
                id="lawyer-email"
                input-name="email"
                placeholder="Ex: jane.doe@company.com"
                rules="required|email">
              </text-input-vertical>
            </div>

            <div class="row">
              <div class="col-sm-6 bottom-30">
                <text-input-vertical
                  v-model="lawyer.firstName"
                  label="First Name"
                  id="first-name"
                  input-name="firstName"
                  placeholder="Ex: Jane"
                  rules="required">
                </text-input-vertical>
              </div>

              <div class="col-sm-6 bottom-30">
                <text-input-vertical
                  v-model="lawyer.lastName"
                  label="Last Name"
                  id="last-name"
                  input-name="lastName"
                  placeholder="Ex: Doe"
                  rules="required">
                </text-input-vertical>
              </div>
            </div>

            <div class="bottom-30" id="k-accountType">
              <label for="account-type">Account Type</label>

              <validation-provider rules="required" v-slot="{ errors }" :mode="passiveAggressive">
                <dropdown-select
                  id-label="account-type"
                  :options="accountTypeOptions"
                  :initial-label="accountTypeOptions[0].label"
                  label-key="label"
                  value-key="slug"
                  v-model="lawyer.accountType"
                  placeholder="Select an account type"
                  :required="true"
                  :is-disabled="isAlsp"
                  :invalid="errors[0]">
                </dropdown-select>

                <div v-if="errors.length" class="error-text top-5">
                  {{ errors[0] }}
                </div>
              </validation-provider>
            </div>

            <div>
              <text-input-vertical
                v-model="lawyer.firmTitle"
                label="Job Title"
                id="firm-title"
                name="firmTitle"
                placeholder="Ex: Partner, Sr. Associate, etc.">
              </text-input-vertical>
            </div>
          </div>

          <div class="row tight-columns">
            <div class="col-sm-4">
              <loading-button name="sendInvite" lb-class="nv-button-blue a-button-size" @lb-click="handleSubmit(sendInvite)">Add User</loading-button>
            </div>

            <div class="col-sm-3 top-20-xs">
              <button type="button" class="nv-button-white a-button-size" @click="closeModal">Cancel</button>
            </div>
          </div>

          <div class="error-text top-10" v-if="failed">
            * One or more required fields are empty. Please fill in the required fields indicated above.
          </div>

          <div class="error-text top-10" v-if="responseError">
            {{ responseError }}
          </div>

          <div v-if="isAlsp" class="size-text-11px semibold-weight top-20">
            <span class="red-text bold-weight">NOTE:</span> This will add an admin user to your account, but will not be shown to clients.
          </div>
        </form>
      </validation-observer>
    </template>
  </priori-modal>
</template>

<script>
import TextInputVertical from 'vue-app/shared/components/text-input-vertical.vue';
import DropdownSelect from 'vue-app/shared/components/dropdown-select.vue';
import NotificationService from 'vue-app/shared/services/notification-service.js';
import PrioriModal from 'vue-app/shared/components/priori-modal.vue';
import SvgIcon from 'vue-app/shared/components/svg-icon.vue';
import LoadingButton from 'vue-app/shared/components/loading-button.vue';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import interactionModes from 'vue-app/shared/mixins/interaction-modes';
import useCurrentUserStore from 'vue-app/stores/current-user.js';

export default {
  name: 'FirmInviteModal',

  components: {
    TextInputVertical,
    DropdownSelect,
    PrioriModal,
    SvgIcon,
    LoadingButton,
    ValidationObserver,
    ValidationProvider
  },

  mixins: [
    interactionModes
  ],

  props: {
    lawFirm: {
      type: Object,
      required: false
    },

    onSendInvite: {
      type: Function,
      required: true
    }
  },

  data() {
    return {
      lawyer: this.initialLawyerState(),
      responseError: ''
    };
  },

  computed: {
    isAlsp() {
      return this.lawFirm && this.lawFirm.alspAccount;
    },

    accountTypeOptions() {
      if (this.isAlsp) {
        return [
          { label: 'Administrator', slug: 'admin' }
        ];
      }
      else {
        return [
          { label: 'Lawyer', slug: 'lawyer' },
          { label: 'Paralegal', slug: 'paralegal' },
          { label: 'Legal Operations Professional', slug: 'legal_operations_professional' },
          { label: 'Firm Administrator', slug: 'admin' }
        ];
      }
    }
  },

  methods: {
    handleSubmit(save) {
      // Need to ensure this is cleared in case the user is re-submitting a
      // valid user after attempting an invalid one.
      this.responseError = '';

      return this.$refs.validationObserver.handleSubmit(save);
    },

    sendInvite() {
      this.onSendInvite(this.lawyer)
      .then(() => {
        this.$refs.modal.closeModal();
        NotificationService.success('You successfully added the user to the firm.');
      })
      .catch(() => {
        this.responseError = 'The selected attorney already has an account with Priori and cannot be added as a new user. Please contact Priori for further information.';
      });
    },

    initialLawyerState() {
      const currentUser = useCurrentUserStore().currentUser;
      const lawyerAccountType = this.lawFirm && this.lawFirm.alspAccount ? 'admin' : 'lawyer';
      const lawFirmId = this.lawFirm ? this.lawFirm.id : currentUser.lawFirmId;

      return {
        email: '',
        firstName: '',
        lastName: '',
        accountType: lawyerAccountType,
        firmTitle: '',
        lawFirmId: lawFirmId,
        invitedById: currentUser.id,
        invitedByType: 'Lawyer'
      };
    },

    reset() {
      this.lawyer = this.initialLawyerState();
      this.responseError = '';
      this.$refs.validationObserver.reset();
    }
  }
};
</script>

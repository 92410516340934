<template>
  <div class="form-section-content">
    <div class="row tight-columns">
      <div class="col-sm-6 bottom-20">
        <text-input-vertical
          label="Project Name"
          :id="`matter-name-${matterId}`"
          :input-name="`matterName${matterId}`"
          input-type="text"
          placeholder="Project Name"
          autocomplete="off"
          v-model="matter.name"
          rules="required">
        </text-input-vertical>
      </div>

      <div class="col-sm-6 bottom-20">
        <text-input-vertical
          label="Client Name"
          :id="`matter-client-name-${matterId}`"
          :input-name="`matterClientName${matterId}`"
          input-type="text"
          placeholder="Client Name"
          autocomplete="off"
          v-model="matter.clientName">
        </text-input-vertical>
      </div>
    </div>

    <div>
      <textarea-input-vertical-with-char-count
        label="Description"
        :id="`matter-description-${matterId}`"
        :name="`matterDescription${matterId}`"
        :max-chars="charLimit"
        :rows="4"
        placeholder="Description of work performed"
        v-model="matter.description">
      </textarea-input-vertical-with-char-count>
    </div>
  </div>
</template>

<script>
import TextInputVertical from 'vue-app/shared/components/text-input-vertical.vue';
import TextareaInputVerticalWithCharCount from 'vue-app/shared/components/textarea-input-vertical-with-char-count.vue';
import { trim } from 'lodash';

export default {
  name: 'RepresentativeMatterFields',

  components: {
    TextInputVertical,
    TextareaInputVerticalWithCharCount
  },

  props: {
    matter: {
      type: Object,
      required: true
    },

    onFormValidityChange: {
      type: Function,
      required: true
    }
  },

  data() {
    return {
      charLimit: 500
    };
  },

  computed: {
    descriptionCharLimitPassed() {
      return this.descriptionCharCount > this.charLimit;
    },

    descriptionCharCount() {
      return trim(this.matter.description).length;
    },

    isValid() {
      return !!this.matter?.name?.length && !this.descriptionCharLimitPassed;
    },

    matterId() {
      return this.matter.id || 'new';
    }
  },

  watch: {
    isValid(newValue, _oldValue) {
      this.onFormValidityChange(newValue);
    }
  },

  mounted() {
    this.onFormValidityChange(this.isValid);
  }
};
</script>

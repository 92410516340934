<template>
  <div id="firm-header" class="shadowed-box">
    <div class="box-content padding-bottom-0">
      <div class="upper-right size-text-12px" v-if="isLawyer">
        <firm-header-edit-modal :law-firm="lawFirm" :on-save="save"></firm-header-edit-modal>
      </div>

      <div class="bottom-5">
        <div class="lawyer-profile-photo right-20-not-xs">
          <profile-photo :name="lawFirm.name"></profile-photo>
        </div>

        <div class="text-img-adjacent">
          <div class="medium-page-heading">
            {{ lawFirm.name }}
          </div>

          <div class="dark-gray-text top-2">
            {{ alspAccount ? 'Law/Tech Company' : lawFirm.firmSize }}
          </div>
        </div>
      </div>

      <div class="header-tabs">
        <div class="tab-switcher">
          <a href="" :class="{ 'selected': isSelected('details') }" @click.prevent="selectTab('details')">Details</a>

          <a href="" :class="{ 'selected': isSelected('lawyers') }" @click.prevent="selectTab('lawyers')" v-if="!alspAccount">Lawyers</a>

          <a href="" :class="{ 'selected': isSelected('people') }" @click.prevent="selectTab('people')" v-if="alspAccount">People</a>

          <a href="" :class="{ 'selected': isSelected('testimonials') }" @click.prevent="selectTab('testimonials')">Testimonials</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ProfilePhoto from 'vue-app/shared/components/profile-photo.vue';
import FirmHeaderEditModal from 'vue-app/marketplace/lawyer/firms/firm-header-edit-modal.vue';

export default {
  name: 'FirmHeader',

  components: {
    ProfilePhoto,
    FirmHeaderEditModal
  },

  props: {
    lawFirm: {
      type: Object,
      required: true
    },

    isLawyer: {
      type: Boolean,
      default: false
    },

    selectedTab: {
      type: String,
      required: true
    },

    onSelectTab: {
      type: Function,
      required: true
    },

    onSave: {
      type: Function,
      required: false
    }
  },

  computed: {
    alspAccount() {
      return this.lawFirm.alspAccount;
    }
  },

  methods: {
    isSelected(tabName) {
      return this.selectedTab === tabName;
    },

    selectTab(tabName) {
      if (this.isSelected(tabName)) { return; }
      this.onSelectTab(tabName);
    },

    save(editedLawFirm) {
      this.onSave(editedLawFirm, 'basic info');
    }
  }
};
</script>

<template>
  <div id="firm-about" class="shadowed-box">
    <div class="box-header">
      <div class="row tight-columns">
        <div :class="['col-xs-12 text-uppercase', { 'col-sm-9': isLawyer }]">
          About
        </div>

        <div class="col-xs-12 col-sm-3 text-right-not-xs" v-if="isLawyer">
          <about-edit-modal :law-firm="lawFirm" :on-save="save"></about-edit-modal>
        </div>
      </div>
    </div>

    <div class="box-content">
      <div id="description-section">
        <div v-if="sanitizedDescription.length > maxLength && !showFullDescription">
          <span class="simple-text">{{ subBio }}... <a href="" class="size-text-12px" @click.prevent="expandDescription">+more</a></span>
        </div>

        <div v-if="sanitizedDescription.length <= maxLength || showFullDescription">
          <span class="simple-text">{{ sanitizedDescription }}</span>
        </div>

        <div class="gray-text size-text-16px" v-if="!sanitizedDescription.length">
          Not Provided
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AboutEditModal from 'vue-app/marketplace/lawyer/firms/about-edit-modal.vue';

export default {
  name: 'FirmAbout',

  components: {
    AboutEditModal
  },

  props: {
    lawFirm: {
      type: Object,
      required: true
    },

    isLawyer: {
      type: Boolean,
      default: false
    },

    onSave: {
      type: Function,
      required: false
    }
  },

  data() {
    return {
      maxLength: 750,
      showFullDescription: false
    };
  },

  computed: {
    sanitizedDescription() {
      return this.lawFirm.sanitizedDescription || '';
    },

    subBio() {
      let subBio = this.sanitizedDescription.substring(0, this.maxLength);
      return subBio.substr(0, Math.min(subBio.length, subBio.lastIndexOf(' ')));
    }
  },

  methods: {
    expandDescription() {
      this.showFullDescription = true;
    },

    save(editedLawFirm) {
      this.onSave(editedLawFirm, 'about');
    }
  }
};
</script>

<template>
  <edit-modal :title="headerText" modal-size="lg" :on-save="save" :input="aggregatedItems" v-model="itemsCopy">
    <template #modal-trigger="{ openModal }">
      <a href="" class="normal-weight size-text-12px" @click.prevent="openModal">Edit</a>
    </template>

    <template #default v-if="itemsCopy">
      <div class="bold-weight text-uppercase bottom-10">
        {{ $pluralize(itemClassName) }}
      </div>

      <hr class="kenny-hr no-margin">

      <div class="form-section-content top-30">
        <div class="overlay-container">
          <typeahead-vertical
            ref="typeahead"
            :label="`Add ${itemClassName}`"
            :id="$pluralize(itemClassName)"
            :options="itemOptions"
            option-label-key="label"
            :placeholder="`Type to search ${lowerCase($pluralize(itemClassName))}`"
            :hide-icon="true"
            @input="addItem">
          </typeahead-vertical>
        </div>
      </div>

      <div class="form-section-content top-30 bottom-40">
        <div class="missing-text top-10 bottom-10" v-if="!hasSelectedItems">
          None provided
        </div>

        <div class="tag-list" v-if="hasSelectedItems">
          <div class="tag-list-item gray delete-variant" v-for="item in visibleItems" :key="item[selectableItemIdAttribute]">
            <span class="right-10">{{ item.label }}</span>

            <a href="" class="tag-list-item-delete" @click.prevent="removeItem(item)"><svg-icon name="x-circle" class="base-icon"></svg-icon></a>
          </div>
        </div>
      </div>
    </template>
  </edit-modal>
</template>

<script>
import EditModal from 'vue-app/shared/components/edit-modal.vue';
import { some, find, uniqueId, isString, omit, lowerCase } from 'lodash';

export default {
  name: 'TypeaheadItemSelectorEditModal',

  components: {
    EditModal
  },

  props: {
    isAlsp: {
      type: Boolean,
      required: true
    },

    lawFirmItems: {
      type: Array,
      required: true
    },

    selectableItems: {
      type: Array,
      required: true
    },

    selectableItemIdAttribute: {
      type: String,
      required: true
    },

    itemClassName: {
      type: String,
      required: true
    },

    onSave: {
      type: Function,
      required: true
    }
  },

  data() {
    return {
      itemsCopy: null
    };
  },

  computed: {
    headerText() {
      return `Editing Your ${this.isAlsp ? 'Company\'s Information' : 'Firm\'s Profile'}`;
    },

    itemOptions() {
      return this.selectableItems.filter(item => !some(this.itemsCopy, { [this.selectableItemIdAttribute]: item.id }));
    },

    aggregatedItems() {
      return this.lawFirmItems.map(item => ({ ...item, _destroy: false }));
    },

    hasSelectedItems() {
      return some(this.itemsCopy, { _destroy: false });
    },

    visibleItems() {
      return this.itemsCopy.filter(item => !item._destroy);
    }
  },

  methods: {
    lowerCase,

    addItem(itemToAdd) {
      if (!itemToAdd) { return; }

      const selectedItem = find(this.itemsCopy, { [this.selectableItemIdAttribute]: itemToAdd.id });

      if (selectedItem) {
        selectedItem._destroy = false;
      }
      else {
        this.itemsCopy.push({
          id:                               uniqueId('new-item-'),
          label:                            itemToAdd.label,
          [this.selectableItemIdAttribute]: itemToAdd.id,
          _destroy:                         false
        });
      }

      this.$refs.typeahead.reset();
    },

    removeItem(itemToRemove) {
      itemToRemove._destroy = true;
    },

    save() {
      const editedLawFirmItems = this.itemsCopy.map(item => {
        return isString(item.id) ? omit(item, 'id') : item;
      });

      this.onSave(editedLawFirmItems);
    }
  }
};
</script>

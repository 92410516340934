<template>
  <div id="firm-practice-areas" class="shadowed-box">
    <div class="box-header">
      <div class="row tight-columns">
        <div class="col-sm-4 text-uppercase">
          Project Experience
        </div>

        <div :class="[{ 'col-sm-7': isLawyer, 'col-sm-8': !isLawyer }, 'text-right-not-xs']">
          <a href="" :class="['header-link', { 'selected': isSelected('projectTypes') }]" @click.prevent="selectTab('projectTypes')">Project Types</a>

          <a href="" :class="['header-link', { 'selected': isSelected('industries') }]" @click.prevent="selectTab('industries')">Industries</a>
        </div>

        <div class="col-sm-1 text-right-not-xs" v-if="isLawyer">
          <checklist-with-counts-edit-modal
            v-if="isSelected('projectTypes')"
            :is-alsp="lawFirm.alspAccount"
            :selectable-items="practiceAreas"
            selected-count-attribute="countProjects"
            counts-section-title="project types"
            item-class-name="ProjectType"
            item-label-key="name"
            :law-firm-items="lawFirmPracticeAreas"
            selectable-item-id-attribute="practiceAreaId"
            :on-save="savePracticeAreas">
          </checklist-with-counts-edit-modal>

          <checklist-with-counts-edit-modal
            v-if="isSelected('industries')"
            :is-alsp="lawFirm.alspAccount"
            :selectable-items="industries"
            selected-count-attribute="countProjects"
            counts-section-title="Industries"
            item-class-name="Industry"
            :law-firm-items="lawFirmIndustries"
            selectable-item-id-attribute="industryId"
            :on-save="saveIndustries">
          </checklist-with-counts-edit-modal>
        </div>
      </div>
    </div>

    <div class="box-content">
      <div class="size-text-12px" v-if="isSelected('projectTypes') && hasPracticeAreas">
        <doughnut-chart-with-legend :chart-data="projectData" :legend-data="projectLegend" :legend-columns="1"></doughnut-chart-with-legend>
      </div>

      <div class="size-text-12px" v-else-if="isSelected('industries') && hasIndustries">
        <doughnut-chart-with-legend :chart-data="industryData" :legend-data="industryLegend" :legend-columns="1"></doughnut-chart-with-legend>
      </div>

      <div class="gray-text size-text-16px" v-else>
        None Provided
      </div>
    </div>
  </div>
</template>

<script>
import DoughnutChartWithLegend from 'vue-app/shared/components/doughnut-chart-with-legend.vue';
import PrioriColors from 'vue-app/shared/misc/priori-colors';
import PracticeArea from 'resources/practice-area.js';
import Industry from 'resources/industry.js';
import ChecklistWithCountsEditModal from 'vue-app/marketplace/lawyer/firms/checklist-with-counts-edit-modal.vue';
import { map, clone, sumBy, round, sortBy } from 'lodash';

export default {
  name: 'AlspProfilePracticeAreas',

  components: {
    ChecklistWithCountsEditModal,
    DoughnutChartWithLegend
  },

  props: {
    lawFirm: {
      type: Object,
      required: true
    },

    isLawyer: {
      type: Boolean,
      default: false
    },

    onSave: {
      type: Function,
      required: false
    }
  },

  data() {
    return {
      selectedTab: 'projectTypes',
      templateMap: {
        projectTypes: 'alsp-practice-areas',
        industries: 'alsp-industries'
      },
      practiceAreas: [],
      industries: []
    };
  },

  computed: {
    lawFirmPracticeAreas() {
      return this.lawFirm.lawFirmPracticeAreasAttributes;
    },

    sortedPracticeAreas() {
      return sortBy(this.lawFirmPracticeAreas, 'name');
    },

    hasPracticeAreas() {
      return this.lawFirmPracticeAreas.length > 0;
    },

    lawFirmIndustries() {
      return this.lawFirm.lawFirmIndustriesAttributes;
    },

    sortedIndustries() {
      return sortBy(this.lawFirmIndustries, 'label');
    },

    hasIndustries() {
      return this.lawFirmIndustries.length > 0;
    },

    projectData() {
      return this.chartDataFor('sortedPracticeAreas');
    },

    projectLegend() {
      return this.legendDataFor('projectData');
    },

    industryData() {
      return this.chartDataFor('sortedIndustries');
    },

    industryLegend() {
      return this.legendDataFor('industryData');
    }
  },

  mounted() {
    PracticeArea.query().then(practiceAreas => { this.practiceAreas = practiceAreas; });
    Industry.query().then(industries => { this.industries = industries; });
  },

  methods: {
    selectTab(tab) {
      this.selectedTab = tab;
    },

    isSelected(tab) {
      return this.selectedTab === tab;
    },

    chartDataFor(attribute) {
      const colors = PrioriColors.generate(this[attribute].length);

      return map(this[attribute], (item) => {
        return {
          name: item.name || item.label,
          color: colors.shift(),
          value: item.countProjects
        };
      });
    },

    legendDataFor(chartData) {
      const total = sumBy(this[chartData], 'value');

      return map(this[chartData], (datapoint) => {
        const newDataPoint = clone(datapoint);
        newDataPoint.value = `${round(((datapoint.value / total) * 100), 0)}%`;
        return newDataPoint;
      });
    },

    savePracticeAreas(lawFirmPracticeAreasAttributes) {
      this.onSave({ ...this.lawFirm, lawFirmPracticeAreasAttributes }, 'project experience');
    },

    saveIndustries(lawFirmIndustriesAttributes) {
      this.onSave({ ...this.lawFirm, lawFirmIndustriesAttributes }, 'project experience');
    }
  }
};
</script>

<style lang="scss" scoped>
  @import "stylesheets/globals/variables";

  .header-link {
    color: $k-dark-gray;
    font-size: 12px;
    font-weight: 400;
    text-decoration: none;

    &:first-child {
      margin-right: 15px;
    }

    &:hover {
      text-decoration: underline;
    }

    &.selected {
      color: $k-darker-gray;
      font-weight: 600;
    }
  }
</style>

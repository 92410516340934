<template>
  <edit-modal :title="headerText" :input="aggregatedLawFirmItems" v-model="itemsCopy" modal-size="lg" :on-save="save" @on-modal-open="onModalOpen">
    <template #modal-trigger="{ openModal }">
      <a href="" class="normal-weight size-text-12px" @click.prevent="openModal">Edit</a>
    </template>

    <template #default>
      <div class="bold-weight text-uppercase bottom-10">
        Add {{ $pluralize(startCase(itemClassName)) }}
      </div>

      <hr class="kenny-hr no-margin">

      <div class="form-section-content top-30 bottom-40">
        <div class="search-box-input-container">
          <input
            type="text"
            name="searchTerm"
            id="searchTerm"
            class="form-control"
            placeholder="Search..."
            autocomplete="off"
            v-model="searchTerm">

          <div class="search-icon">
            <svg-icon class="base-icon gray" name="search"></svg-icon>
          </div>
        </div>

        <div class="search-list standalone top-20">
          <div class="search-list-item-container" v-for="item in filteredItems" :key="item.id">
            <div class="search-list-item" v-if="!isSelected(item)" @click="onAddItem(item)">
              <div class="search-list-item-name" v-html="highlightText(item[itemLabelKey])"></div>

              <div class="search-list-item-link">
                <div class="right-5 bold-weight text-uppercase size-text-12px">
                  Add
                </div>

                <svg-icon class="base-icon hoverable" name="plus-circle"></svg-icon>
              </div>
            </div>

            <div class="search-list-item" v-if="isSelected(item)">
              <div class="search-list-item-name">
                <span class="blurred-text">{{ item[itemLabelKey] }}</span>
              </div>

              <div class="search-list-item-link disabled">
                <div class="right-5 bold-weight text-uppercase size-text-12px">
                  Added
                </div>

                <svg-icon class="base-icon green" name="checkmark"></svg-icon>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="bold-weight text-uppercase bottom-10">
        {{ countsSectionTitle }}
      </div>

      <hr class="kenny-hr no-margin">

      <div class="form-section-content top-30">
        <div
          v-for="(lawFirmItem, index) in selectedLawFirmItems"
          class="row vertical-center list-spacing-20"
          :key="lawFirmItem.selectableItemIdAttribute">
          <div class="col-xs-8">
            <div class="text-ellipsis">
              {{ lawFirmItem[itemLabelKey] }}
            </div>
          </div>

          <div class="col-xs-4 text-right">
            <div>
              <text-input-vertical
                input-type="number"
                :name="`firm${itemClassName}${index}`"
                :id="`firm-${kebabCase(itemClassName)}-${index}`"
                :placeholder="isAlsp ? 'Number of projects' : ''"
                :min="0"
                :max="10000"
                :step="1"
                v-model="lawFirmItem[selectedCountAttribute]"
                rules="required|numeric|minValue:0"
                :show-error-text="false">
              </text-input-vertical>

              <div @click="onRemoveItem(lawFirmItem)">
                <svg-icon name="x3" class="deselect-icon for-input"></svg-icon>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </edit-modal>
</template>

<script>
import Vue from 'vue';
import { filter, find, uniqueId, omit, isString, kebabCase, startCase } from 'lodash';
import EditModal from 'vue-app/shared/components/edit-modal.vue';
import TextInputVertical from 'vue-app/shared/components/text-input-vertical.vue';
import SvgIcon from 'vue-app/shared/components/svg-icon.vue';

export default {
  name: 'ChecklistWithCountsEditModal',

  components: {
    EditModal,
    TextInputVertical,
    SvgIcon
  },

  props: {
    isAlsp: {
      type: Boolean,
      required: true
    },

    itemClassName: {
      type: String,
      required: true
    },

    itemLabelKey: {
      type: String,
      default: 'label'
    },

    selectableItems: {
      type: Array,
      required: true
    },

    lawFirmItems: {
      type: Array,
      required: true
    },

    countsSectionTitle: {
      type: String,
      required: true
    },

    selectableItemIdAttribute: {
      type: String,
      required: true
    },

    selectedCountAttribute: {
      type: String,
      required: true
    },

    onSave: {
      type: Function,
      required: true
    }
  },

  data() {
    return {
      searchTerm: '',
      itemsCopy: null
    };
  },

  computed: {
    headerText() {
      return `Editing Your ${this.isAlsp ? 'Company\'s Information' : 'Firm\'s Profile'}`;
    },

    filteredItems() {
      const re = new RegExp(this.searchTerm, 'gi');
      return filter(this.selectableItems, (item) => { return item[this.itemLabelKey].match(re); });
    },

    aggregatedLawFirmItems() {
      return this.lawFirmItems.map(item => ({ ...item, _destroy: false }));
    },

    selectedLawFirmItems() {
      return this.itemsCopy.filter(item => !item._destroy);
    }
  },

  methods: {
    kebabCase,

    startCase,

    onModalOpen() {
      this.searchTerm = '';
    },

    highlightText(text) {
      return text.replace(new RegExp(this.searchTerm, 'gi'), match => `<span class="filter-match">${match}</span>`);
    },

    onAddItem(itemToAdd) {
      const lawFirmItem = find(this.itemsCopy, { [this.selectableItemIdAttribute]: itemToAdd.id });

      if (lawFirmItem) {
        lawFirmItem._destroy = false;
        lawFirmItem[this.selectedCountAttribute] = 1;
      }
      else {
        this.itemsCopy.push({
          id:                               uniqueId('new-item-'),
          [this.itemLabelKey]:              itemToAdd[this.itemLabelKey],
          [this.selectableItemIdAttribute]: itemToAdd.id,
          countLawyers:                     1,
          countPartners:                    0
        });
      }
    },

    isSelected(item) {
      return this.selectedLawFirmItems.some(it => it[this.selectableItemIdAttribute] === item.id);
    },

    onRemoveItem(lawFirmItemToRemove) {
      Vue.set(lawFirmItemToRemove, '_destroy', true);
    },

    save() {
      const editedLawFirmItems = this.itemsCopy.map(item => {
        return isString(item.id) ? omit(item, 'id') : item;
      });

      this.onSave(editedLawFirmItems);
    }
  }
};
</script>

<style lang="scss" scoped>
  @import "stylesheets/globals/variables";

  .base-icon {
    &.gray {
      fill: $k-dark-gray;
    }

    &.green {
      fill: $k-green;
    }
  }

  .search-list-item-container {
    &:hover {
      .hoverable {
        fill: $k-blue;
      }
    }
  }
</style>

<template>
  <div class="form-section-content">
    <div class="bottom-20">
      <text-input-vertical
        label="Client Name"
        :id="`client-name-${clientId}`"
        :input-name="`clientName${clientId}`"
        input-type="text"
        placeholder="Client Name"
        autocomplete="off"
        v-model="client.name"
        rules="required">
      </text-input-vertical>
    </div>

    <div>
      <textarea-input-vertical
        label="Description"
        :id="`client-description-${clientId}`"
        :name="`clientDescription${clientId}`"
        :rows="4"
        placeholder="Description of work performed"
        v-model="client.description">
      </textarea-input-vertical>
    </div>
  </div>
</template>

<script>
import TextInputVertical from 'vue-app/shared/components/text-input-vertical.vue';
import TextareaInputVertical from 'vue-app/shared/components/textarea-input-vertical.vue';
import { trim } from 'lodash';

export default {
  name: 'RepresentativeClientFields',

  components: {
    TextInputVertical,
    TextareaInputVertical
  },

  props: {
    client: {
      type: Object,
      required: true
    },

    onFormValidityChange: {
      type: Function,
      required: true
    }
  },

  data() {
    return {
      charLimit: 500
    };
  },

  computed: {
    descriptionCharCount() {
      return trim(this.client.description).length;
    },

    isValid() {
      return this.client.name && (this.descriptionCharCount <= this.charLimit);
    },

    clientId() {
      return this.client.id || 'new';
    }
  },

  watch: {
    isValid(newValue, _oldValue) {
      this.onFormValidityChange(newValue);
    }
  },

  mounted() {
    this.onFormValidityChange(this.isValid);
  }
};
</script>

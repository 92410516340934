<template>
  <edit-modal :title="headerText" :input="lawFirmItems" v-model="itemsCopy" modal-size="lg" :on-save="save" @on-modal-open="onModalOpen">
    <template #modal-trigger="{ openModal }">
      <a href="" class="normal-weight size-text-12px" @click.prevent="openModal">Edit</a>
    </template>

    <template #default>
      <div class="bold-weight text-uppercase bottom-10">
        Add {{ startCase($pluralize(itemClassName)) }}
      </div>

      <hr class="kenny-hr no-margin">

      <div class="form-section-content top-30">
        <div class="row tight-columns">
          <div class="col-xs-8">
            <label :for="kebabCase(itemClassName)" class="sr-only">{{ placeholder }}</label>

            <input
              type="text"
              :name="camelCase(itemClassName)"
              :id="kebabCase(itemClassName)"
              class="form-control"
              :placeholder="placeholder"
              autocomplete="off"
              v-model="inputValue">
          </div>

          <div class="col-xs-4">
            <button
              type="button"
              class="nv-button-blue a-button-size"
              @click="addItem(inputValue)"
              :disabled="!inputValue">
              Add
            </button>
          </div>
        </div>

        <div class="tag-list top-20" v-if="itemsCopy.length">
          <div class="tag-list-item gray delete-variant" v-for="(item, index) in itemsCopy" :key="index">
            <span class="right-10">{{ item }}</span>

            <a href="" class="tag-list-item-delete" @click.prevent="removeItem(item)"><svg-icon name="x-circle" class="base-icon"></svg-icon></a>
          </div>
        </div>

        <div class="size-text-base gray-text text-center top-20" v-if="!itemsCopy.length">
          None Provided
        </div>
      </div>
    </template>
  </edit-modal>
</template>

<script>
import EditModal from 'vue-app/shared/components/edit-modal.vue';
import SvgIcon from 'vue-app/shared/components/svg-icon.vue';
import { startCase, camelCase, kebabCase } from 'lodash';

export default {
  name: 'TextArrayEditModal',

  components: {
    EditModal,
    SvgIcon
  },

  props: {
    isAlsp: {
      type: Boolean,
      required: true
    },

    itemClassName: {
      type: String,
      required: true
    },

    lawFirmItems: {
      type: Array,
      required: true
    },

    placeholder: {
      type: String,
      default: ''
    },

    onSave: {
      type: Function,
      required: true
    }
  },

  data() {
    return {
      inputValue: '',
      itemsCopy: []
    };
  },

  computed: {
    headerText() {
      return `Editing Your ${this.isAlsp ? 'Company\'s Information' : 'Firm\'s Profile'}`;
    }
  },

  methods: {
    startCase,
    camelCase,
    kebabCase,

    onModalOpen() {
      this.inputValue = '';
    },

    addItem(itemToAdd) {
      if (!this.itemsCopy.includes(itemToAdd)) {
        this.itemsCopy.push(itemToAdd);
      }

      this.inputValue = '';
    },

    removeItem(itemToRemove) {
      const index = this.itemsCopy.indexOf(itemToRemove);

      if (index !== -1) {
        this.itemsCopy.splice(index, 1);
      }
    },

    save() {
      this.onSave(this.itemsCopy);
    }
  }
};
</script>

<template>
  <div class="shadowed-box">
    <div class="box-header">
      <div class="row">
        <div class="col-sm-7">
          <span class="text-uppercase">Lawyers</span>
          <span class="left-10 dark-gray-text normal-weight size-text-12px">({{ firmUserCount }} {{ pluralize('Result', firmUserCount) }})</span>
        </div>

        <div class="col-sm-5">
          <div class="text-right-not-xs" v-if="showInviteLink">
            <firm-invite-modal :law-firm="lawFirm" :on-send-invite="onSendInvite"></firm-invite-modal>
          </div>
        </div>
      </div>
    </div>

    <div class="resource-list table-responsive">
      <loading-section name="users">
        <table id="firm-users" class="table">
          <thead>
            <tr>
              <th class="photo-cell"></th>
              <th class="name-cell">Name</th>
              <th class="text-center">Locations</th>
              <th class="text-center">Practice Area(s)</th>
              <th class="text-center" v-if="showOptionsColumn"></th>
            </tr>
          </thead>

          <tbody>
            <tr class="firm-user-row" v-for="user in firmUsers" :key="user.id">
              <td class="photo-cell">
                <profile-photo :user="user" :medium="true"></profile-photo>
              </td>

              <td>
                <div class="size-text-17px text-ellipsis">
                  {{ user.fullName }}
                </div>

                <div class="text-ellipsis">
                  {{ user.firmTitle }}
                </div>
              </td>

              <td class="text-center">
                {{ formattedAddress(user) || '&mdash;' }}
              </td>

              <td class="text-center">
                <dropdown v-if="hasPracticeAreas(user)" class="auto-width" menu-right>
                  <button type="button" class="pseudo-link-blue dark-gray-link">{{ user.practiceAreas.length }} Practice {{ pluralize('Area', user.practiceAreas.length) }}</button>

                  <template #dropdown>
                    <div class="dropdown-options">
                      <div class="dropdown-content">
                        <span class="compact size-text-12px" v-for="practiceArea in user.practiceAreas">{{ practiceArea }}</span>
                      </div>
                    </div>
                  </template>
                </dropdown>

                <span v-if="!hasPracticeAreas(user)">&mdash;</span>
              </td>

              <td class="text-center" v-if="showOptionsColumn">
                <dropdown-menu :compact="true" v-if="showOptionsLink(user)">
                  <li class="menu-item" role="menuitem">
                    <a :href="`/lawyer-app/profile/${user.id}`" target="_blank">Edit Profile</a>
                  </li>

                  <li class="menu-item" role="menuitem">
                    <a :href="`/lawyer-app/matching-preferences/${user.id}`" target="_blank">Edit Matching Information</a>
                  </li>

                  <li class="menu-item" role="menuitem">
                    <a :href="`/lawyer-app/availability/${user.id}`" target="_blank">Manage Availability</a>
                  </li>
                </dropdown-menu>

                <span v-if="!showOptionsLink(user)">&mdash;</span>
              </td>
            </tr>
          </tbody>
        </table>
      </loading-section>
    </div>

    <div class="box-content" v-if="firmUsers.length">
      <priori-pagination
        :current-page="page"
        :total-entries="firmUserCount"
        :per-page="10"
        @change="onChangePage">
      </priori-pagination>
    </div>
  </div>
</template>

<script>
import LoadingSection from 'vue-app/shared/components/loading-section.vue';
import ProfilePhoto from 'vue-app/shared/components/profile-photo.vue';
import DropdownMenu from 'vue-app/shared/components/dropdown-menu.vue';
import PrioriPagination from 'vue-app/shared/components/priori-pagination.vue';
import FirmInviteModal from 'vue-app/marketplace/shared/law-firms/firm-invite-modal.vue';

export default {
  name: 'FirmUsers',

  components: {
    LoadingSection,
    ProfilePhoto,
    DropdownMenu,
    PrioriPagination,
    FirmInviteModal
  },

  props: {
    firmUsers: {
      type:    Array,
      default: () => []
    },

    firmUserCount: {
      type:     Number,
      default: () => 0
    },

    showInviteLink: {
      type:     Boolean,
      required: true
    },

    showOptionsColumn: {
      type:     Boolean,
      required: true
    },

    page: {
      type:     Number,
      required: true
    },

    lawFirm: {
      type:     Object,
      required: true
    },

    onChangePage: {
      type:     Function,
      required: true
    },

    onSendInvite: {
      type:     Function,
      required: false
    }
  },

  methods: {
    pluralize(word, count) {
      return this.$pluralize(word, count);
    },

    formattedAddress(user) {
      if (!user.address) { return; }

      return user.address.city || user.address.country;
    },

    hasPracticeAreas(user) {
      return user.practiceAreas.length > 0;
    },

    showOptionsLink(user) {
      return this.showOptionsColumn && user.hasSignedIn;
    }
  }
};
</script>

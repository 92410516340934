<template>
  <div class="summary clearfix vertical-center-not-xs">
    <div class="canvas-container">
      <doughnut-chart :chart-data="chartData" :width="doughnutWidth" :height="doughnutHeight" :cutout-percentage="doughnutCutoutPercentage">
        <slot></slot>
      </doughnut-chart>
    </div>

    <div class="legend">
      <chart-legends :legend-data="legendDataOrChartData" :column-count="legendColumnCount" :initial-max-legends="legendMaxLegends"></chart-legends>
    </div>
  </div>
</template>

<script>
import DoughnutChart from './doughnut-chart.vue';
import ChartLegends from './chart-legends.vue';

export default {
  name: 'DoughnutChartWithLegend',

  components: {
    DoughnutChart,
    ChartLegends
  },

  props: {
    chartData: {
      type:     Array,
      required: true
    },

    legendData: {
      type:     Array,
      default:  () => []
    },

    doughnutHeight: {
      type:    String,
      default: '200px'
    },

    doughnutWidth: {
      type:    String,
      default: '200px'
    },

    doughnutCutoutPercentage: {
      type:     Number,
      required: false
    },

    legendColumns: {
      type: Number,
      default: 2
    },

    legendMaxLegends: {
      type: Number,
      required: false
    },

    memo: {
      type: String
    }
  },

  computed: {
    legendDataOrChartData() {
      return this.legendData.length === 0 ? this.chartData : this.legendData;
    },

    legendColumnCount() {
      return this.chartData.length > 5 ? this.legendColumns : 1;
    }
  }
};
</script>

<style lang="scss" scoped>
  .summary {
    width: 100%;
    position: relative;
    text-align: center;

    @media (min-width: 768px) {
      .canvas-container, .legend {
        float: left;
      }

      .canvas-container {
        padding-right: 20px;
      }
    }

    @media (min-width: 992px) {
      .canvas-container {
        padding-right: 30px;
      }
    }

    .canvas-container .doughnut-chart {
      position: relative;
    }
  }

  .legend {
    @media (min-width: 768px) {
      width: calc(100% - 220px);
    }

    @media (min-width: 992px) {
      width: calc(100% - 230px);
    }
  }
</style>

<template>
  <div class="doughnut-chart">
    <div class="doughnut-container" :style="{ 'width': width, 'height': height }">
      <canvas ref="canvas"></canvas>
    </div>

    <slot></slot>
  </div>
</template>

<script>
import Chart from 'chart.js';
import { map, some } from 'lodash';

export default {
  props: {
    chartData: {
      type:     Array,
      required: true
    },

    height: {
      type:    String,
      default: '200px'
    },

    width: {
      type:    String,
      default: '200px'
    },

    cutoutPercentage: {
      type:    Number,
      default: 62
    }
  },

  data() {
    return {
      chartObject: null
    };
  },

  computed: {
    labels() {
      return map(this.chartData, 'name');
    },

    dataValues() {
      return map(this.chartData, 'value');
    },

    colors() {
      return map(this.chartData, 'color');
    }
  },

  mounted() {
    if (some(this.chartData, item => item.value)) {
      this.chartObject = this.renderChart();
    }
    else {
      this.renderEmptyChart();
    }
  },

  updated() {
    this.chartObject?.destroy();

    if (some(this.chartData, item => item.value)) {
      this.chartObject = this.renderChart();
    }
    else {
      this.chartObject = this.renderEmptyChart();
    }
  },

  methods: {
    renderChart() {
      return new Chart(this.$refs.canvas, {
        type: 'doughnut',

        data: {
          labels: this.labels,

          datasets: [{
            data:            this.dataValues,
            backgroundColor: this.colors
          }]
        },

        options: {
          responsive:       true,
          aspectRatio:      1,
          cutoutPercentage: this.cutoutPercentage,

          legend:    { display: false },
          animation: { duration: 0 },

          tooltips: {
            enabled: false
          }
        }
      });
    },

    renderEmptyChart() {
      const canvas = this.$refs.canvas;
      const strokeWidth = 1;
      const numericWidth = parseFloat(this.width);
      const halfWidth = Math.round(numericWidth / 2);
      const ringThickness = halfWidth * Math.abs(this.cutoutPercentage - 100) * 0.01;
      const innerCircleStartingPointX = numericWidth + strokeWidth - ringThickness;
      const innerCircleStartingPointY = halfWidth + strokeWidth;
      const canvasContext = canvas.getContext('2d');

      // initialize canvas
      canvas.width = numericWidth + 2;
      canvas.height = numericWidth + 2;
      canvasContext.clearRect(0, 0, numericWidth, numericWidth);
      canvasContext.lineWidth = strokeWidth;

      // draw outer circle
      canvasContext.arc(halfWidth + strokeWidth, halfWidth + strokeWidth, halfWidth, 0, 2 * Math.PI);

      // draw inner circle
      canvasContext.moveTo(innerCircleStartingPointX, innerCircleStartingPointY);
      canvasContext.arc(halfWidth + strokeWidth, halfWidth + strokeWidth, halfWidth - ringThickness, 0, 2 * Math.PI);
      canvasContext.stroke();
    }
  }
};
</script>

<style lang="scss" scoped>
  .doughnut-chart {
    position: relative;
  }

  .doughnut-container {
    margin: 0 auto;

    @media (min-width: 768px) {
      margin: 0;
    }

    canvas {
      position: absolute;
    }
  }
</style>

<template>
  <div class="form-section-content">
    <div class="row tight-columns">
      <div class="col-sm-6 bottom-20">
        <text-input-vertical
          label="First Name"
          :input-name="`memberFirstName${memberId}`"
          :id="`member-first-name-${memberId}`"
          placeholder="First Name"
          v-model="member.firstName"
          rules="required">
        </text-input-vertical>
      </div>

      <div class="col-sm-6 bottom-20">
        <text-input-vertical
          label="Last Name"
          :input-name="`memberLastName${memberId}`"
          :id="`member-last-name-${memberId}`"
          placeholder="Last Name"
          v-model="member.lastName"
          rules="required">
        </text-input-vertical>
      </div>
    </div>

    <div class="bottom-20">
      <text-input-vertical-with-char-count
        label="Title"
        :id="`member-title-${memberId}`"
        :name="`memberTitle${memberId}`"
        :max-chars="100"
        placeholder="Title"
        v-model="member.title"
        rules="required">
      </text-input-vertical-with-char-count>
    </div>

    <div class="row tight-columns">
      <div class="col-sm-4 bottom-20">
        <typeahead-vertical
          :ref="`countryTypeahead${memberId}`"
          label="Country"
          :id="`member-country-${memberId}`"
          :options="countryOptions"
          :hide-icon="true"
          :value="member.country"
          placeholder="Country"
          @input="selectCountry($event)"
          rules="required">
        </typeahead-vertical>
      </div>

      <div class="col-sm-4 bottom-20">
        <typeahead-vertical
          :ref="`stateTypeahead${memberId}`"
          label="State"
          :id="`member-state-${memberId}`"
          :options="stateOptions"
          :option-label-key="'name'"
          :option-value-key="'name'"
          :hide-icon="true"
          :value="member.state || ''"
          :placeholder="statePlaceholder"
          @input="selectState($event)"
          :disabled="!isInUS"
          :rules="isInUS ? 'required' : ''">
        </typeahead-vertical>
      </div>

      <div class="col-sm-4 bottom-20">
        <text-input-vertical
          label="City"
          :id="`member-city-${memberId}`"
          :input-name="`city${memberId}`"
          placeholder="City"
          v-model="member.city"
          rules="required">
        </text-input-vertical>
      </div>
    </div>

    <div>
      <textarea-input-vertical-with-char-count
        label="Bio"
        :id="`member-bio-${memberId}`"
        :name="`memberBio${memberId}`"
        :rows="4"
        :max-words="500"
        placeholder="Personal bio"
        v-model="member.bio">
      </textarea-input-vertical-with-char-count>
    </div>
  </div>
</template>

<script>
import TextInputVertical from 'vue-app/shared/components/text-input-vertical.vue';
import TextareaInputVerticalWithCharCount from 'vue-app/shared/components/textarea-input-vertical-with-char-count.vue';
import TextInputVerticalWithCharCount from 'vue-app/shared/components/text-input-vertical-with-char-count.vue';
import TypeaheadVertical from 'vue-app/shared/components/typeahead-vertical.vue';
import SharedListsService from 'vue-app/shared/services/shared-lists-service.js';
import { words } from 'lodash';

export default {
  name: 'AlspMemberFields',

  components: {
    TextInputVertical,
    TextareaInputVerticalWithCharCount,
    TextInputVerticalWithCharCount,
    TypeaheadVertical
  },

  props: {
    member: {
      type: Object,
      required: true
    },

    onFormValidityChange: {
      type: Function,
      required: true
    }
  },

  data() {
    return {
      wordLimit: 500,
      countries: [],
      states: []
    };
  },

  computed: {
    stateOptions() {
      return SharedListsService.listOfStateObjects();
    },

    countryOptions() {
      return SharedListsService.listOfCountries();
    },

    isInUS() {
      return this.member.country === 'United States';
    },

    statePlaceholder() {
      return this.isInUS ? 'State' : 'N/A';
    },

    memberId() {
      return this.member.id || 'new';
    },

    isValid() {
      const { firstName, lastName, title, country, state, city, bio } = this.member;

      const isBioValid   = words(bio).length <= this.wordLimit;
      const isTitleValid = title?.length <= 100;
      const isStateValid = !this.isInUS || state;

      return firstName && lastName && title && country && city && isBioValid && isTitleValid && isStateValid;
    },

    fullName() {
      return `${this.member.firstName} ${this.member.lastName}`;
    }
  },

  watch: {
    isValid(newValue, _oldValue) {
      this.onFormValidityChange(newValue);
    },

    fullName(newValue) {
      this.member.fullName = newValue;
    }
  },

  mounted() {
    this.onFormValidityChange(this.isValid);
  },

  methods: {
    selectCountry(country) {
      this.member.country = country;

      if (!this.isInUS) {
        this.$refs[`stateTypeahead${this.memberId}`].reset();
      }
    },

    selectState(state) {
      this.member.state = state;
    }
  }
};
</script>

<template>
  <edit-modal :title="headerText" modal-size="lg" :on-save="save" :input="aggreggatedItems" v-model="itemsCopy">
    <template #modal-trigger="{ openModal }">
      <slot name="modalTrigger" :open-modal="openModal">
        <a href="" class="normal-weight size-text-12px" @click.prevent="openModal">Edit</a>
      </slot>
    </template>

    <template #default>
      <div class="bold-weight text-uppercase bottom-10">
        {{ $pluralize(startCase(itemClassName)) }}
      </div>

      <hr class="kenny-hr no-margin">

      <div class="form-section-content top-30 bottom-50">
        <div
          v-for="(item, index) in visibleItems"
          :key="item.id || item.auxiliaryId">
          <div class="top-20 bottom-20" v-if="index !== 0">
            <hr class="kenny-hr no-margin">
          </div>

          <div v-if="item.isEditing">
            <div class="row bottom-20">
              <div class="col-sm-7 semibold-weight">
                {{ boldText(index, item) }}
              </div>

              <div class="col-sm-5 edit-links">
                <button :class="['text-button', { 'disabled': !formIsValid, 'active': formIsValid }]" @click="formIsValid && onSaveItem(item)">
                  (Collapse)
                </button>

                <span @click="onEditItemCanceled(item)">(Cancel)</span>
              </div>
            </div>

            <slot name="inputFields" :item="item" :set-form-validity="setFormValidity"></slot>
          </div>

          <div v-else class="bottom-10">
            <div class="row bottom-10">
              <div class="col-sm-7 semibold-weight">
                {{ item[itemTitleKey] }}<span v-if="itemTitleDetailKey && item[itemTitleDetailKey]"> ({{ item[itemTitleDetailKey] }})</span>

                <div v-if="itemSubtitleKey && item[itemSubtitleKey]" class="dark-gray-text top-2">
                  {{ item[itemSubtitleKey] }}
                </div>
              </div>

              <div class="col-sm-5 edit-links" v-if="!editingItem && !item.isDeleting">
                <button class="text-button" @click="onEditItem(item)">
                  (Edit)
                </button>

                <button class="text-button delete" @click="deletingItem(item)">
                  (Delete)
                </button>
              </div>

              <div class="col-sm-5 edit-links" v-if="item.isDeleting">
                <div class="delete">
                  Are you sure you want to delete this item?
                </div>

                <button class="text-button" @click="cancelDelete(item)">
                  (No)
                </button>

                <button class="text-button delete left-5" @click="onRemove(item)">
                  (Yes)
                </button>
              </div>
            </div>

            <div>
              <span class="simple-text line-height-1">{{ item[itemDescriptionKey] }}</span>
            </div>
          </div>
        </div>

        <div class="top-20" v-if="!editingItem">
          <button
            type="button"
            class="empty-button a-button-size"
            @click="onAdd"
            :disabled="addButtonDisabled">
            {{ addItemButtonText }}
          </button>
        </div>
      </div>
    </template>
  </edit-modal>
</template>

<script>
import EditModal from 'vue-app/shared/components/edit-modal.vue';
import { startCase, uniqueId } from 'lodash';

export default {
  name: 'MultifieldLineItemsEditModal',

  components: {
    EditModal
  },

  props: {
    isAlsp: {
      type: Boolean,
      required: true
    },

    lawFirmItems: {
      type: Array,
      required: true
    },

    itemClassName: {
      type: String,
      required: true
    },

    itemTitleKey: {
      type: String,
      required: true
    },

    itemTitleDetailKey: {
      type: String,
      required: false
    },

    itemSubtitleKey: {
      type: String,
      required: false
    },

    itemDescriptionKey: {
      type: String,
      required: true
    },

    itemInitializeData: {
      type: Object,
      required: false
    },

    itemLimit: {
      type: Number,
      required: false
    },

    singleItemName: {
      type: String,
      required: true
    },

    onSave: {
      type: Function,
      required: true
    }
  },

  data() {
    return {
      itemsCopy: null,
      formIsValid: false
    };
  },

  computed: {
    aggreggatedItems() {
      return this.lawFirmItems.map(item => (
        {
          ...item,
          isEditing: false,
          isDeleting: false,
          _destroy: false
        }
      ));
    },

    visibleItems() {
      return this.itemsCopy.filter(item => !item._destroy);
    },

    headerText() {
      return `Editing Your ${this.isAlsp ? 'Company\'s Information' : 'Firm\'s Profile'}`;
    },

    editingItem() {
      return this.itemsCopy.find(m => (m.isEditing));
    },

    addItemButtonText() {
      const determiner = this.visibleItems.length >= 1 ? 'Another' : 'A';

      return `Add ${determiner} ${startCase(this.singleItemName)}`;
    },

    addButtonDisabled() {
      if (!this.itemLimit) { return false; }

      return this.itemsCopy.filter(item => !item._destroy).length >= this.itemLimit;
    }
  },

  methods: {
    startCase,

    deletingItem(item) {
      item.isDeleting = true;
    },

    cancelDelete(item) {
      item.isDeleting = false;
    },

    onRemove(item) {
      item._destroy = true;
    },

    onSaveItem(item) {
      item.isEditing = false;
    },

    isNewItem(item) {
      return !item.id;
    },

    boldText(index, item) {
      if (this.isNewItem(item)) {
        return `Adding New ${startCase(this.itemClassName)}`;
      }
      else {
        return this.lawFirmItems[index][this.itemTitleKey];
      }
    },

    onEditItem(item) {
      this.itemsCopy.forEach(item => item.isEditing = false);
      item.isEditing = true;
    },

    onEditItemCanceled(item) {
      if (item.id) {
        const editingItemIndex = this.itemsCopy.findIndex(m => m.id === item.id);
        const originalItem     = this.lawFirmItems.find(m => m.id === item.id);

        this.itemsCopy.splice(editingItemIndex, 1, {
          ...originalItem,
          isEditing: false,
          isDeleting: false,
          _destroy: false
        });
      }
      else {
        this.itemsCopy.splice(this.itemsCopy.indexOf(item), 1);
      }
    },

    setFormValidity(valid) {
      this.formIsValid = valid;
    },

    onAdd() {
      this.itemsCopy.push({ ...this.itemInitializeData, auxiliaryId: uniqueId('new'), isEditing: true, isDeleting: false, _destroy: false });
    },

    save() {
      this.onSave(this.itemsCopy);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "stylesheets/globals/variables";

.text-button {
  background: none;
  border: none;
  padding-right: 0;
  font-weight: 600;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }

  &.active {
    border-color: $k-blue;
    color: $k-blue;
    cursor: pointer;
  }

  &.disabled {
    color: $k-dark-gray;
    cursor: default;
    text-decoration: none;
  }
}
</style>

<template>
  <div class="bar-chart-wrapper">
    <ul class="no-bullets">
      <li v-for="(item, index) in chartData" :key="index">
        <div class="item-name">
          <span v-if="item.icon" class="item-icon">
            <svg-icon class="base-icon" :name="item.icon"></svg-icon>
          </span>

          {{ item.name }}
        </div>

        <div class="item-value">
          <span class="bar vertical-middle" :style="stylesFor(item)"></span>
          <span class="total vertical-middle">{{ formattedValue(item.value) }}{{ unitSymbol }}</span>
        </div>
      </li>
    </ul>

    <canvas v-if="asPercent" class="percentage-panel" ref="canvas" :width="percentagePanelWidth" :height="totalHeight"></canvas>
  </div>
</template>

<script>
import { map, max } from 'lodash';
import { Chart } from 'chart.js';

export default {
  props: {
    chartData: {
      type:     Array,
      required: true
    },

    asPercent: {
      type: Boolean,
      default: false
    },

    barThickness: {
      type:    Number,
      default: 16
    },

    maxBarLength: {
      type:    Number,
      default: 140
    },

    unitSymbol: {
      type: String,
      required: false
    }
  },

  computed: {
    labels: function () {
      return map(this.chartData, 'name');
    },

    dataValues: function () {
      return map(this.chartData, 'value');
    },

    colors: function () {
      return map(this.chartData, 'color');
    },

    totalHeight: function () {
      const height = 2 * this.barThickness * this.chartData.length;

      return `${height}px`;
    },

    maxItemValue: function () {
      if (this.asPercent) { return 100; }
      return max(this.dataValues);
    },

    percentagePanelWidth: function () {
      return '170px';
    }
  },

  mounted: function () {
    if (this.asPercent) { this.renderChart(); }
  },

  methods: {
    stylesFor: function (item) {
      return {
        width: `${this.widthFor(item)}px`,
        backgroundColor: item.color
      };
    },

    widthFor: function (item) {
      if (!this.maxItemValue) { return 0; }

      const modifier = this.asPercent ? item.percentage : item.value;

      return Math.floor((modifier / this.maxItemValue) * this.maxBarLength);
    },

    formattedValue(value) {
      return value.toLocaleString();
    },

    renderChart: function () {
      new Chart(this.$refs.canvas, {
        type: 'horizontalBar',

        data: {},

        options: {
          responsive: false,
          legend:    { display: false },
          animation: { duration: 0 },

          layout: {
            padding: {
              right: 0
            }
          },

          scales: {
            xAxes:  [{
              ticks: {
                min: 0,
                max: 100
              }
            }],

            yAxes: [{
              display: false
            }]
          }
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
  .bar-chart-wrapper {
    position: relative;
  }

  ul {
    z-index: 50;
    position: relative;

    li {
      margin: 15px 0;
      font-size: 12px;

      &:first-child {
        margin-top: 0;
      }

      &:last-child {
        margin-bottom: 0;
      }

      .item-name {
        width: 220px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        vertical-align: top;
        line-height: 20px;
      }

      .item-value {
        .bar {
          height: 16px;
          display: inline-block;
          margin-right: 5px;
          border-radius: 4px;
        }

        .total {
          font-size: 11px;
          font-weight: 600;
          vertical-align: top;
          line-height: 20px;
        }
      }
    }
  }

  @media(min-width: 768px) {
    ul li {
      margin: 8px 0;

      .item-name, .item-value {
        display: inline-block;
      }

      .item-name {
        width: 212px;
        text-align: right;
      }

      .item-value {
        margin-left: 10px;
      }
    }
  }

  @media(min-width: 992px) {
    ul li .item-name {
      width: 180px;
    }
  }

  @media(min-width: 1024px) {
    ul li .item-name {
      width: 205px;
    }
  }

  @media(min-width: 1200px) {
    ul li .item-name {
      width: 300px;
    }
  }

  .percentage-panel {
    top: 0;
    left: 0;
    z-index: 30;
    position: absolute;

    @media(min-width: 768px) {
      left: 206px;
    }

    @media(min-width: 1024px) {
      left: 236px;
    }

    @media(min-width: 1200px) {
      left: 306px;
    }
  }

  .item-icon .base-icon {
    vertical-align: text-bottom;
  }
</style>

<template>
  <div id="firm-representative-matters" class="shadowed-box">
    <div class="box-header">
      <div class="row">
        <div :class="['col-xs-12 text-uppercase', { 'col-sm-9': isLawyer }]">
          {{ lawFirm.alspAccount ? 'Example Projects' : 'Representative Matters' }}
        </div>

        <div class="col-xs-12 col-sm-3 text-right-not-xs">
          <multifield-line-items-edit-modal
            v-if="isLawyer"
            :is-alsp="lawFirm.alspAccount"
            :law-firm-items="lawFirm.lawFirmRepresentativeMattersAttributes"
            :single-item-name="lawFirm.alspAccount ? 'Project' : 'Matter'"
            :item-class-name="lawFirm.alspAccount ? 'ExampleProject' : 'RepresentativeMatter'"
            item-title-key="name"
            item-subtitle-key="clientName"
            item-description-key="description"
            :item-limit="3"
            :on-save="save">
            <template #inputFields="{ item: matter, setFormValidity }">
              <representative-matter-fields
                :matter="matter"
                :on-form-validity-change="setFormValidity">
              </representative-matter-fields>
            </template>
          </multifield-line-items-edit-modal>
        </div>
      </div>
    </div>

    <div class="box-content">
      <div v-for="(matter, index) in displayedMatters" :key="matter.id">
        <div class="semibold-weight size-text-16px">
          {{ matter.name }}
        </div>

        <div class="dark-gray-text semibold-weight top-2" v-if="hasClientName(matter)">
          {{ matter.clientName }}
        </div>

        <div class="top-15" v-if="hasDescription(matter)">
          <span class="simple-text line-height-1">{{ matter.description }}</span>
        </div>

        <hr class="kenny-hr" v-if="!isLastMatter(index)">
      </div>

      <div class="gray-text size-text-16px" v-if="!hasRepresentativeMatters">
        None Provided
      </div>
    </div>

    <div class="box-footer text-center" v-if="!showAll && representativeMatters.length > 3">
      <a href="" class="darker-gray-text semibold-weight size-text-13px" @click.prevent="viewAll">Show All</a>
    </div>
  </div>
</template>

<script>
import MultifieldLineItemsEditModal from 'vue-app/marketplace/lawyer/firms/multifield-line-items-edit-modal.vue';
import RepresentativeMatterFields from 'vue-app/marketplace/lawyer/firms/representative-matter-fields.vue';

export default {
  name: 'FirmProfileRepresentativeMatters',

  components: {
    MultifieldLineItemsEditModal,
    RepresentativeMatterFields
  },

  props: {
    lawFirm: {
      type: Object,
      required: true
    },

    isLawyer: {
      type: Boolean,
      default: false
    },

    onSave: {
      type: Function,
      required: false
    }
  },

  data() {
    return {
      showAll: false
    };
  },

  computed: {
    representativeMatters() {
      return this.lawFirm.lawFirmRepresentativeMattersAttributes;
    },

    hasRepresentativeMatters() {
      return this.representativeMatters.length > 0;
    },

    displayedMatters() {
      if (this.showAll) {
        return this.representativeMatters;
      }
      else {
        return this.representativeMatters.slice(0, 3);
      }
    }
  },

  methods: {
    hasClientName(matter) {
      return matter.clientName && matter.clientName.length;
    },

    hasDescription(matter) {
      return matter.description && matter.description.length;
    },

    isLastMatter(index) {
      return index === this.displayedMatters.length - 1;
    },

    viewAll() {
      this.showAll = true;
    },

    save(lawFirmRepresentativeMattersAttributes) {
      return this.onSave({ ...this.lawFirm, lawFirmRepresentativeMattersAttributes }, 'representative matters');
    }
  }
};
</script>

<template>
  <edit-modal :title="headerText" modal-size="lg" :on-save="save" :on-cancel="cancel" :input="lawFirm" v-model="lawFirmCopy">
    <template #modal-trigger="{ openModal }">
      <a href="" class="normal-weight size-text-12px" @click.prevent="openModal">Edit</a>
    </template>

    <template #default>
      <div class="bold-weight text-uppercase bottom-10">
        Basic Info
      </div>

      <hr class="kenny-hr no-margin">

      <div class="form-section-content top-30 bottom-50">
        <div class="bottom-20">
          <label for="law-firm-name">{{ lawFirm.alspAccount ? 'Company' : 'Firm' }} Name <span class="dark-gray-text normal-weight">(limit {{ wordLimit }} words)</span></label>

          <text-input-vertical-with-word-count
            id="law-firm-name"
            name="lawFirmName"
            :max-words="wordLimit"
            v-model="lawFirmCopy.name"
            rules="required">
          </text-input-vertical-with-word-count>
        </div>

        <div>
          <label for="firm-size">{{ lawFirm.alspAccount ? 'Company' : 'Firm' }} Size</label>

          <div class="row">
            <div class="col-sm-6">
              <validation-provider rules="required" v-slot="{ errors }" :mode="passiveAggressive">
                <dropdown-select
                  ref="firmSizeDropdown"
                  id-label="firm-size"
                  :options="firmSizes"
                  :initial-label="lawFirmCopy.firmSize"
                  v-model="lawFirmCopy.firmSize"
                  :allow-null="false"
                  :show-placeholder="false"
                  :required="true"
                  :invalid="errors[0]">
                </dropdown-select>

                <div v-if="errors.length" class="error-text top-5">
                  {{ errors[0] }}
                </div>
              </validation-provider>
            </div>
          </div>
        </div>
      </div>
    </template>
  </edit-modal>
</template>

<script>
import EditModal from 'vue-app/shared/components/edit-modal.vue';
import TextInputVerticalWithWordCount from 'vue-app/shared/components/text-input-vertical-with-word-count.vue';
import SharedListsService from 'vue-app/shared/services/shared-lists-service.js';
import DropdownSelect from 'vue-app/shared/components/dropdown-select.vue';
import { ValidationProvider } from 'vee-validate';
import interactionModes from 'vue-app/shared/mixins/interaction-modes';

export default {
  name: 'FirmHeaderEditModal',

  components: {
    EditModal,
    DropdownSelect,
    TextInputVerticalWithWordCount,
    ValidationProvider
  },

  mixins: [
    interactionModes
  ],

  props: {
    lawFirm: {
      type: Object,
      required: true
    },

    onSave: {
      type: Function,
      required: true
    }
  },

  data() {
    return {
      wordLimit: 20,
      lawFirmCopy: null
    };
  },

  computed: {
    headerText() {
      return `Editing Your ${this.lawFirm.alspAccount ? 'Company\'s Information' : 'Firm\'s Profile'}`;
    },

    firmSizes() {
      return SharedListsService.organizationSizes(this.lawFirm.alspAccount);
    }
  },

  methods: {
    save() {
      this.onSave(this.lawFirmCopy);
    },

    cancel() {
      this.$refs.firmSizeDropdown.reset();
    }
  }
};
</script>

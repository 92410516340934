<template>
  <div id="alsp-technologies" class="shadowed-box">
    <div class="box-header text-uppercase">
      Technology
    </div>

    <div class="box-content">
      <div class="bottom-30">
        <div class="row tight-columns bottom-5">
          <div :class="['col-xs-12 semibold-weight', { 'col-sm-9': isLawyer }]">
            Types
          </div>

          <div class="col-xs-12 col-sm-3 text-right-not-xs" v-if="isLawyer">
            <text-array-edit-modal
              :is-alsp="lawFirm.alspAccount"
              :law-firm-items="technologies.types"
              placeholder="Name"
              item-class-name="Technology"
              :on-save="saveTypes">
            </text-array-edit-modal>
          </div>
        </div>

        <hr class="kenny-hr no-margin">

        <div class="gray-text top-10" v-if="!hasTypes">
          None Provided
        </div>

        <div class="tag-list top-10" v-if="hasTypes">
          <div class="tag-list-item gray compact" v-for="typeName in lawFirm.alspTechnologyAttributes.types" :key="typeName">
            {{ typeName }}
          </div>
        </div>
      </div>

      <div>
        <div class="row tight-columns bottom-5">
          <div :class="['col-xs-12 semibold-weight', { 'col-sm-9': isLawyer }]">
            Security Certifications
          </div>

          <div class="col-xs-12 col-sm-3 text-right-not-xs" v-if="isLawyer">
            <text-array-edit-modal
              :is-alsp="lawFirm.alspAccount"
              :law-firm-items="technologies.securityCertifications"
              placeholder="Name"
              item-class-name="SecurityCertification"
              :on-save="saveSecurityCertifications">
            </text-array-edit-modal>
          </div>
        </div>

        <hr class="kenny-hr no-margin">

        <div class="gray-text top-10" v-if="!hasSecurityCertifications">
          None Provided
        </div>

        <div class="tag-list top-10" v-if="hasSecurityCertifications">
          <div class="tag-list-item gray compact" v-for="certification in lawFirm.alspTechnologyAttributes.securityCertifications" :key="certification">
            {{ certification }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import textArrayEditModal from 'vue-app/marketplace/lawyer/firms/text-array-edit-modal.vue';

export default {
  name: 'AlspTechnology',

  components: {
    textArrayEditModal
  },

  props: {
    lawFirm: {
      type: Object,
      required: true
    },

    isLawyer: {
      type: Boolean,
      default: false
    },

    onSave: {
      type: Function,
      required: false
    }
  },

  computed: {
    technologies() {
      return this.lawFirm.alspTechnologyAttributes || { types: [], securityCertifications: [] };
    },

    hasTypes() {
      return !!this.technologies.types.length;
    },

    hasSecurityCertifications() {
      return !!this.technologies.securityCertifications.length;
    }
  },

  methods: {
    saveTypes(types) {
      const alspTechnologyAttributes = { ...this.lawFirm.alspTechnologyAttributes, types };

      return this.onSave({ ...this.lawFirm, alspTechnologyAttributes }, 'technology types');
    },

    saveSecurityCertifications(securityCertifications) {
      const alspTechnologyAttributes = { ...this.lawFirm.alspTechnologyAttributes, securityCertifications };

      return this.onSave({ ...this.lawFirm, alspTechnologyAttributes }, 'security certifications');
    }
  }
};
</script>

<template>
  <span class="chart-legend vertical-center">
    <span class="bullet-container">
      <span class="bullet" :style="{ 'backgroundColor': item.color }"></span>
    </span>

    <span class="name-container">
      <span :class="['right-5', { 'text-italic': italicized }]">{{ item.name }}</span>
      <span class="semibold-weight">({{ item.value }})</span>
    </span>
  </span>
</template>

<script>
export default {
  name: 'ChartLegend',

  props: {
    item: {
      type: Object,
      required: true
    },

    italicized: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style lang="scss" scoped>
  .chart-legend {
    font-size: 12px;
  }

  .bullet-container {
    display: inline-block;
    width: 20px;
    line-height: 16px;

    .bullet {
      display: block;
      width: 10px;
      height: 10px;
      border-radius: 50px;
    }
  }

  .name-container {
    display: inline-block;
    width: calc(100% - 25px);
    line-height: 16px;
  }
</style>

<template>
  <div class="row" v-if="showPagination">
    <div :class="['col-xs-12', { 'col-sm-10': showPageInfo }]">
      <ul class="pagination">
        <li class="pagination-item">
          <button type="button" @click="goPrevious" :disabled="isOnFirstPage">Previous Page</button>
        </li>

        <div class="visible-xs top-5 bottom-5"></div>

        <li v-for="page in pageRange" :key="pageNumber(page)">
          <button type="button" @click="goToPage(page)" :disabled="isCurrentPage(page)" :class="{ current: isCurrentPage(page) }">{{ pageText(page) }}</button>
        </li>

        <div class="visible-xs top-5 bottom-5"></div>

        <li class="pagination-item">
          <button type="button" @click="goNext" :disabled="isOnLastPage">Next Page</button>
        </li>
      </ul>
    </div>

    <div class="col-xs-12 col-sm-2 text-right-not-xs" v-if="showPageInfo">
      {{ pageInfo }}
    </div>
  </div>
</template>

<script>
import { isNumber, range } from 'lodash';

export default {
  name: 'PrioriPagination',

  props: {
    currentPage: {
      type: Number,
      required: true
    },

    totalEntries: {
      type: Number,
      required: true
    },

    perPage: {
      type: Number,
      required: true
    },

    showPageInfo: {
      type: Boolean,
      default: true
    }
  },

  data() {
    return {
      siblingCount: 2
    };
  },

  computed: {
    totalPages() {
      return Math.ceil(this.totalEntries / this.perPage);
    },

    showPagination() {
      return this.totalEntries > 0;
    },

    isOnFirstPage() {
      return this.currentPage === 1;
    },

    isOnLastPage() {
      return this.currentPage === this.totalPages;
    },

    fromEntry() {
      return ((this.currentPage - 1) * this.perPage) + 1;
    },

    toEntry() {
      if (this.isOnLastPage) {
        return this.totalEntries;
      }
      else {
        return this.currentPage * this.perPage;
      }
    },

    pageInfo() {
      return `${this.fromEntry}-${this.toEntry} of ${this.totalEntries}`;
    },

    windowCount() {
      // 2 * siblings + current
      return (2 * this.siblingCount) + 1;
    },

    leftSiblingPage() {
      return Math.max(this.currentPage - this.siblingCount, 1);
    },

    rightSiblingPage() {
      return Math.min(this.currentPage + this.siblingCount, this.totalPages);
    },

    shouldShowLeftEllipsis() {
      return this.leftSiblingPage > 3;
    },

    shouldShowRightEllipsis() {
      return this.rightSiblingPage < this.totalPages - 2;
    },

    shouldShowAllPages() {
      // window + first + last
      let minPageCount = this.windowCount + 2;
      return minPageCount >= this.totalPages || (!this.shouldShowLeftEllipsis && !this.shouldShowRightEllipsis);
    },

    fullPageRange() {
      return range(1, this.totalPages + 1);
    },

    leftPageRange() {
      let leftRange = range(1, this.windowCount + 1);
      return [...leftRange, this.ellipsis(this.windowCount + 1), this.totalPages];
    },

    middlePageRange() {
      let middleRange = range(this.leftSiblingPage, this.rightSiblingPage + 1);
      return [1, this.ellipsis(this.leftSiblingPage - 1), ...middleRange, this.ellipsis(this.rightSiblingPage + 1), this.totalPages];
    },

    rightPageRange() {
      let rangeStart = this.totalPages - this.windowCount + 1;
      let rightRange = range(rangeStart, this.totalPages + 1);
      return [1, this.ellipsis(rangeStart - 1), ...rightRange];
    },

    pageRange() {
      if (this.shouldShowAllPages) {
        return this.fullPageRange;
      }
      else if (!this.shouldShowLeftEllipsis && this.shouldShowRightEllipsis) {
        return this.leftPageRange;
      }
      else if (this.shouldShowLeftEllipsis && !this.shouldShowRightEllipsis) {
        return this.rightPageRange;
      }
      else {
        return this.middlePageRange;
      }
    }
  },

  methods: {
    isCurrentPage(page) {
      return this.currentPage === page;
    },

    ellipsis(page) {
      return { page: page };
    },

    isEllipsis(page) {
      return !isNumber(page);
    },

    pageText(page) {
      return this.isEllipsis(page) ? '...' : page;
    },

    pageNumber(page) {
      return this.isEllipsis(page) ? page.page : page;
    },

    goPrevious() {
      if (this.isOnFirstPage) { return; }
      this.goToPage(this.currentPage - 1);
    },

    goNext() {
      if (this.isOnLastPage) { return; }
      this.goToPage(this.currentPage + 1);
    },

    goToPage(page) {
      if (this.isCurrentPage(page)) { return; }
      this.$emit('change', this.pageNumber(page));
    }
  }
};
</script>

<style lang="scss" scoped>
  @import "stylesheets/scout/variables";

  .pagination {
    margin: 0;

    li {
      margin-right: 14px;

      &:last-child {
        margin-right: 0;
      }

      button {
        background: none;
        color: inherit;
        border: none;
        padding: 0;
        font: inherit;
        cursor: pointer;
        outline: inherit;

        &:hover {
          text-decoration: underline;
        }

        &:disabled {
          color: $k-gray;

          &:hover {
            cursor: default;
            text-decoration: none;
          }
        }

        &.current {
          color: $k-blue;
          text-decoration: underline;
        }
      }
    }
  }

  .scout-pagination .pagination {
    font-weight: 600;
  }
</style>
